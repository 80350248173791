import { createAsyncThunk } from '@reduxjs/toolkit';
import { PORT, PATH, MODE } from "services/api";

const userLogin = createAsyncThunk('auth/login', async ({email, password, shouldRememberMe}) => {

    const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/login`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "email": email,
            "password": password,
            "isPersistent": shouldRememberMe
        })
    }).then(rawResponse => {
        if (rawResponse.ok) {
            return rawResponse.json();
        }
        throw rawResponse.statusText;
    });

    return response;
});

const verifyTempPassword = createAsyncThunk('auth/verifyTempPassword', async ({email, tempPassword}) => {
    // TODO: TEMP-FIX URL and body needs to be changed later.
    const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/register`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "email": email,
            "password": tempPassword
        })
    }).then(rawResponse => {
        if (rawResponse.ok) {
            return rawResponse.json();
        }
        throw rawResponse.statusText;
    });

    return response;
});

const registerUser = createAsyncThunk('auth/registerUser', async ({email, name, department, newPassword}) => {

    const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/register`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "email": email,
            "name": name,
            "department": department,
            "newPassword": newPassword
        })
    }).then(rawResponse => {
        if (rawResponse.ok) {
            return rawResponse.json();
        }
        throw rawResponse.statusText;
    });

    return response;
});



export { userLogin, verifyTempPassword, registerUser };