export const theme = {

    // Use 30px as the baseline fontsize calculation

    heading1: {
        fontSize: '2.25rem',
        fontWeight: '500',
    },

    heading2: {
        fontSize: '1.69rem',
        fontWeight: '400',
    },

    heading3: {
        fontSize: '1.125rem',
        fontWeight: '300',
    },

    heading4: {
        fontSize: '0.936rem',
        fontWeight: '500',
    },

    heading5: {
        fontSize: '0.75rem',
        fontWeight: '300',
    },

    heading6: {
        fontSize: '0.5rem',
        fontWeight: '300',
    },

    heading7: {
        fontSize: '0.5rem',
        fontWeight: '300',
    },

    bodyText: {
        fontSize: '0.625rem',
        fontWeight: '400',
    },

    greatingsHeading: {
        monitor: {
            fontSize: '3rem',
            fontWeight: '500',
        },
        large_desktop: {
            fontSize: '2rem',
            fontWeight: '500',
        },
        laptop: {
            fontSize: '1.8rem',
            fontWeight: '500',
        },
    },
    
    greatingsSubHeading: {
        monitor: {
            fontSize: '1rem',
            fontWeight: '500',
        },
        large_desktop: {
            fontSize: '0.73rem',
            fontWeight: '500',
        },
        laptop: {
            fontSize: '0.6rem',
            fontWeight: '500',
        },
    },

    infoTitleHeading: {
        monitor: {
            fontSize: '3rem',
            fontWeight: '400',
        },
        large_desktop: {
            fontSize: '2rem',
            fontWeight: '400',
        },
        laptop: {
            fontSize: '1.7rem',
            fontWeight: '400',
        },
    },

    infoTitleSubHeading: {
        monitor: {
            fontSize: '1rem',
            fontWeight: '300',
        },
        large_desktop: {
            fontSize: '0.73rem',
            fontWeight: '300',
        },
        laptop: {
            fontSize: '0.6rem',
            fontWeight: '300',
        },
    },
    
    errors: {
        monitor: {
            fontSize: '0.583rem',
            fontWeight: '300',
        },
        large_desktop: {
            fontSize: '0.583rem',
            fontWeight: '300',
        },
        laptop: {
            fontSize: '0.5rem',
            fontWeight: '300',
        },
    },

    colors: {
        
        background: '#F7F7F6',      
        generalWhite: '#FAFAF9',    // tile, filters, text, header, etc
        black: '#164050',
        darkGrey: '#6C7073',        // secondary text
        lightGrey: '#C4C4C4',       // unavailable button/ data 
        gaaGreen: '#1D7A2F',
        darkestPurple: '#787595',     // footer, some clickable text 
        commonPurple: '#9F8CAE',      // filters, some text, buttons 
        secondaryBlue: '#53BBDF',   // Slider, Graphs,etc 
    
        text: {
            generalText: "#164050",
            secondaryText: "#6C7073",
            clickableText: "#787595",
            clickableText_2: "#9F8CAE",
            disabledText: "#C4C4C4",
            whiteText: "#FAFAF9",
        }
    }

}