import styled, { keyframes } from "styled-components";
import backgroundImage from 'assets/image/header_background.png';
import { media } from 'utils/Static/index';



export const HomeHeaderComponent = styled.div`
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center center;
    position: relative;
    margin-bottom: 5vh;
    /* position: -webkit-sticky; */
    top: 0;
    /* height: ${props => props.isHeaderCollapsed ? `5.5vh` : `95vh`}; */
    height: 81.1vh;
    width: 100%;
    /* z-index: ${props => props.isScrolled ? `100` : `0`}; */
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    /* gap: 25%; */
    justify-content: space-around;
    align-items: center;
    
    align-self: flex-start;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    padding: 0 1.2vw;

    ${media.monitor} {
        min-height: 59px;
    }

    ${media.large_desktop} {
        min-height: 51px;
    }

    ${media.laptop} {
        min-height: 40px;
    }

    /* transition: all 0.5s; */
`;


export const LogoComponent = styled.div`
    display: flex;
    align-items: center;

    #org-logo {
        height: 65px;
    }

    ${media.monitor} {
        #org-logo {
            height: 45px;
        }
    }

    ${media.large_desktop} {
        #org-logo {
            height: 34px;
        }
    }

    ${media.laptop} {
        #org-logo {
            height: 26.7px;
        }
    }
`;

export const TitleImage = styled.img`
    height: 40px;
    opacity: ${props => props.isScroll ? '1' : `0`};

    transition: all 2s;

    ${media.monitor} {
        height: 28px;
    }

    ${media.large_desktop} {
        height: 20px;
    }

    ${media.laptop} {
        height: 20px;
    }
`;


export const TitleImage2 = styled.img`
    height: 5.8vh;
    padding: 1.6vh 1.2vw 2.5vh 1.2vw;
`;

export const SearchBar = styled.div`
    width: 52.5vw;
    height: 5.5vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    border-radius: 50px;
    padding: 0px 2px 0px 2vw;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.4);;
    

    background-color: #FAFAF9;

    & > input {
        height: 4vh;
        width: 42vw;
        background: transparent;
        border: none;
        font-size: ${props=> props.theme.heading5.fontSize};
        font-weight: ${props=> props.theme.heading5.fontWeight};
        color: #381650;
    }

    & > input:focus {
        outline: none;
    }

    & > input::placeholder {
        color: #381650;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #381650;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #381650;
    }
`;


export const SubmitBtn = styled.div`
    box-sizing: border-box;
    background-color: #4DBBF1;
    margin-right: .2vw;
    padding: 15px 10px;
    width: 15%;
    height: 80%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 40px;
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};

    cursor: pointer;

    ${media.large_desktop} {
        padding: 5px 10px;
    }

    ${media.laptop} {
        padding: 3px 10px;
    }
`;


export const HeaderTitleContainer = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    justify-content: space-between;

    padding: 2vh 1vw;
    
`

export const HeaderTitle = styled.div`
    display: flex;
    align-items: center;

    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: ${props => props.theme.heading3.fontWeight};
    font-size: ${props => props.theme.heading3.fontSize};
    line-height: 1.5rem;

    color: #FFFFFF;

    #org-logo{
        height: 3.5vh;
    }

`

export const TitleContent = styled.div`

`

export const AnalyticsWapper = styled.div`
    display: flex;
    /* display: ${props => props.isHeaderCollapsed ? 'none': 'flex'}; */
    /* visibility: ${props => props.isHeaderCollapsed ? 'collapse': 'visible'}; */
    /* flex-direction: ${props => props.isScroll ? `row` : `column`}; */
    flex-direction: column;
    align-items: center;
    /* gap: ${props => props.isScroll && `8vw`}; */
    /* justify-content: ${props => !props.isScroll && `center`}; */
    /* justify-content: center; */


    /* flex-grow: ${props => !props.isScroll && 1}; */
    flex-grow: 1;
    width: 100%;
    /* margin-bottom: ${props => !props.isScroll && "10vh"}; */
    /* margin-bottom: 10vh; */

    /* transition: all 1s; */
`

export const SwitchTabWrapper = styled.div`
    /* position: relative; */
    width: 94%;
    height: 7vh;
    /* padding: 1vh 10vw; */

    display: flex;
    flex-direction: row;
    gap: 1.75vw;
    align-items: center;
`
export const SwitchTab = styled.button`
    height: auto;
    width: auto;

    padding: 0.7rem 1.8rem;

    background: ${props => props.isSelected == 1 ? `#1EBBEE` : `#FFFFFF`};
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: .7vw;
    border: none;

    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: ${props => props.theme.heading3.fontWeight};
    font-size: ${props => props.theme.heading3.fontSize};

    color: ${props => props.isSelected == 1 ? `#FFFFFF` : `#5C2583`};

    :disabled {
        background: #F0F0EF;
        color: #6C7073;
        box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
        cursor: not-allowed;
    }

    &:hover:enabled {
        background: ${props => props.isSelected == 1 ? `#1EBBEE` : `#5095D5`};
        color: #FFFFFF;
    }

    ${media.large_desktop} {
        font-size: 1rem;
    }

    ${media.desktop} {
        font-size: 0.9rem;
    }

    ${media.laptop} {
        font-size: 0.6rem;
    }

`

export const AnalyticsCardWapper = styled.div`
    width: 94%;
    height: 52vh;
    margin: 2vh 0;
    display: flex;
    flex-direction: row;

    gap: 1vw;
`
export const FocusArea = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 2vh;

    height: 87%;
    width: 26vw;
    flex-grow: 1;
`
export const TotalCompanies = styled.div`
    width: 100%;
    height: 27%;
    flex-grow: 1;

    background-color: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 16px;
`
export const FundingCompanies = styled.div`
    width: 100%;
    height: 27%;

    background-color: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 16px;
    flex-grow: 1;

`
export const TotalCompanyCard = styled.div`
    height: 87%;
    width: 26vw;
    flex-grow: 1;

    background-color: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 16px;
`
export const ActiveCompanyPeriod = styled.div`
    height: 87%;
    width: 26vw;
    flex-grow: 1;

    background-color: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 16px;
`

export const CompanyByState = styled.div`
    height: 87%;
    width: auto;
    max-width: 33%;
    flex-grow: 1;

    background-color: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 16px;
`
export const FocusAreaDisUSA = styled.div`
    height: 87%;
    width: auto;
    max-width: 33%;
    flex-grow: 1.3;

    background-color: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 16px;
`
export const CompanyPie = styled.div`
    height: 87%;
    width: auto;
    max-width: 33%;
    flex-grow: 1;

    background-color: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 16px;
`
export const PieHomeWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 1vh 3vw;
    box-sizing: border-box;
    overflow-y: auto;
`

export const SummaryTitleAnly = styled.span`
    align-self: flex-start;
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: 500;
    color: #381650;
`
export const TotalFundingAmountAnly = styled.div`
    box-sizing: border-box;
    width: 80%;
    margin: auto 0;
    padding: 1vh;
    background-color: #5C2583;
    color: #FFFFFF;
    /* border-radius: 8px; */
    text-align: center;

    font-size: ${props => props.theme.heading2.fontSize};
    font-weight: ${props => props.theme.heading2.fontWeight};
    overflow: hidden;
    /* text-align: center; */
`

export const ScrollLogoContainer = styled.div`
    width: 100%;
    height: calc(50px + 4vh);
    padding-bottom: 2vh;

    position: relative;


    /* display: flex;
    align-items: center; */
`

export const FundingAmountWrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: .5vw;
`

const ScrollLogoAnimation = keyframes`
    0%{
        bottom: 2vh;
    }
    30%{
        bottom: 5vh;
    }
    60%{
        bottom: 2vh;
    }
    80%{
        bottom: 3vh;
    }
    100%{
        bottom: 2vh;
    }
`

export const ScrollLogo = styled.div`
    position: absolute;
    right: 10px;
    bottom: 2vh;
    
    height: 50px;
    width: 50px;

    border-radius: 50%;
    background-color: #ffff;

    color: #5C2583;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 5vh;

    & :hover{
        cursor: pointer;
    }

    animation-name: ${props => props.animate ? ScrollLogoAnimation : `none` } ;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
     
`

// export const Path = styled.path`
//     pointer-events: all;

//     :hover {
//         opacity: 0.50;
//         cursor: pointer;
//     }
// `