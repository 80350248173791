// import { useRoutes } from 'react-router';
import { Breadcrumb, Link, Typography } from './style.backdrop';
import withRouter from 'utils/ComponentWrapper';
import Parser from 'html-react-parser';

const getDisplayName = (input_pathNames) => {
    // Cater for the path of list page
    if (input_pathNames.length >= 2 && input_pathNames[1] === "list") {
        input_pathNames[1] += " of all ";
        switch (input_pathNames[0]) {
            case "clinicalTrials":
                input_pathNames[1] += "Trials";
                break;
            case "vaccineAvailability":
                input_pathNames[1] += "Vaccines";
                break;
            case "funding":
                input_pathNames[1] += "Projects";
                break;
            default:
                break;
        }
    }
    return input_pathNames;
}

const Breadcrumbs = (props) => {
    const histories = props.history;
    // const {
    //     history, location: {
    //         pathname
    //     }
    // } = props;
    const pathname = props.location.pathname;
    const pathNames = getDisplayName(pathname.split('/').filter(x => x));
    
    
    return (
        <Breadcrumb>
            <span style={{color: "#381650", cursor:"pointer"}}>{`Global Action Alliance`}&nbsp;&nbsp;{`>`}</span>
            <Link onClick={() => { histories('/') }}>{Parser('JLABS WISE-R<sup>TM</sup>')}</Link>
            {
                pathNames.map((name, index) => {
                    const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
                    const lastIndex = pathNames.length - 1 === index;

                    const tempName = name.split(/(?=[A-Z])/);
                    let dispName = tempName.join(" ");
                    let modifiedTempName = dispName.split('%20');
                    dispName = modifiedTempName.join(" ");
                    modifiedTempName = dispName.split('%');
                    dispName = modifiedTempName.join("");

                    return (
                        lastIndex ?
                            <Typography key={index}>{dispName[0].toUpperCase() + dispName.substring(1)}</Typography>
                            :
                            <span key={index} style={{color: "#381650"}} onClick={() => { histories(routeTo) }}>
                                {dispName[0].toUpperCase() + dispName.substring(1)}
                            </span>
                    );
                })
            }
        </Breadcrumb>
    );
}

export default withRouter(Breadcrumbs);