import styled from "styled-components";

export const InfoBubbleWrapper = styled.div`
    
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-self: center; /*flex-start;*/
    text-align: center;
    font-size: 0.7rem;
    ${'' /* color: #23647C; */}

    &::after, &::before {
        --scale: 0;
        --tooltip-color: transparent;
        position: absolute;
        left:50%;
        bottom: -.15rem;
        outline: none;
        /* z-index: 1; */
        max-height: 17vh;
        overflow-y: auto;
        background: #ffffff;
        box-shadow: 3px 12px 20px rgba(0, 0, 0, 0.25);
        transform: translateX(-50%) translateY(var(--translate-y,0)) scale(var(--scale));
        transition: 100ms transform;
    }
    
    &::before{
        display: ${props => props.disabled ? 'none' : 'block'};
        --translate-y: calc(100% + 16px );
        
        content: attr(data-tooltip);
        color:  #381650;
        padding: 0.73vh 0.4vw;
        width: max-content;
        max-width: 20vw;
        ${'' /* max-width: 10vw; */}
        height: auto;
        background: #FFFFFF;
        ${'' /* background: var(--tooltip-color); */}
        
        font-size: ${props=> props.theme.heading6.fontSize};
        border-radius: 4px;
        transform-origin: top center;
    }
    &:hover::before,
    &:hover::after {
        --scale: 1;
        z-index: 1;
    }
    ${'' /* &::after{
        --translate-y: 16px;
        content: '';
        border: 6px solid transparent;
        transform-origin: bottom center;
    } */}
`