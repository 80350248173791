import styled from 'styled-components';
import { media } from 'utils/Static/index';

export const FooterContainer = styled.div`
    box-sizing: border-box;
    position:  ${(props) => !props.relative && "fixed"};
    bottom: 0px;
    padding: 1.1vh 0;
    margin-top: auto;
    background-color: #5C2583;
    color : #FFFFFF;
    width: 100%;
    height: 5.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};
`
export const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`
export const FooterHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: .1vh;

    #org-logo {
        height: 20px;
        margin-right: 3px;
    }

    ${media.monitor} {
        #org-logo {
            height: 15px;
        }
    }

    ${media.large_desktop} {
        #org-logo {
            height: 10px;
        }
    }

    ${media.laptop} {
        #org-logo {
            height: 7px;
        }
    }
`
export const FooterLink = styled.a`
    text-decoration: none;
    line-height: 14px;
    color : #FFF;

    &:hover {
        text-decoration: underline;
        color : #CFDBE3;
    }

    &.disabled {
        text-decoration: none;
        color : #FFF;
    }
`
export const FooterLinkSeparator = styled.div`
    font-weight: ${props=> props.theme.heading6.fontWeight};
    font-size: ${props=> props.theme.heading6.fontSize};
    margin : 0px 8px;
`