import styled from "styled-components";
import { media } from 'utils/Static/index';

export const TagsContainer = styled.div`
`

export const TagsHeading = styled.p`
    margin: 0 0 2px 0;
    color: #381650;
    font-size: ${props=> props.theme.heading6.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
`

export const TagsContentShort = styled.p`
    margin: 0;
    padding: 1vh 1.15vw;
    background-color: ${props=> props.color};
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    max-width: 15vw;
    /* white-space: nowrap; */
    text-align: center;
    color: #381650;
    /* cursor: pointer; */
    position: relative;
`;


export const TagsContent = styled.p`
    margin: 0;
    padding: 1vh 1.15vw;
    background-color: ${props=> props.color};
    /* text-overflow: ellipsis; */
    max-width: 10vw;
    /* white-space: nowrap; */
    text-align: center;
    color: #381650;
    /* cursor: pointer; */
    position: relative;

    &::after, &::before{
        --scale: 0;
        --tooltip-color: #DBEAF3;
        --arrow-size: 7px;

        position: absolute;
        left:50%;
        bottom: -.2rem;
        outline: none;
        /* z-index: 1; */

        max-height: 17vh;
        overflow-y: auto;

        
        transform: translateX(-50%) translateY(var(--translate-y,0)) scale(var(--scale));
        transition: 100ms transform;

    }
    
    &::before{
        --translate-y: calc(100% + var(--arrow-size));
        
        content: attr(data-tooltip);
        color:  #164050;
        padding: .7vw;
        width: max-content;
        max-width: 20vw;
        height: auto;
        background: var(--tooltip-color);
        
        font-size: ${props=> props.theme.heading6.fontSize};
        border-radius: 6px;

        transform-origin: top center;
    }

    &:hover::before,
    &:hover::after{
        --scale: 1;
        z-index: 1;
    }

    &::after{
        --translate-y: var(--arrow-size);

        content: '';
        border: 6px solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    }
`