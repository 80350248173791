import { theme } from './static.theme';
import styled from "styled-components";

export const gridItem = `
    border-radius: 15px;
    text-align: left;
    font-weight: 300;
    color: #468AAB;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    background-color: #FAFAF9;
`  // Grid item configuration

export const homeInputStyle = `
    border: none; 
    outline: none; 
    height: 4vh; 
    padding-left: 20px; 
    color: #381650; 

    background-color: inherit; 
    cursor: pointer;
`

export const TextButton = styled.a`
    color: #584A9F;4
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #787595;
    }
`

export const viewMoreStyle = `
    font-size: 1.083rem;
    font-weight: 300;
    color: #468AAB;
    background: none;
    border: 2px solid #468AAB;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    
    &:hover {
        cursor: pointer;
        color: #FAFAF9;
        background-color: #468AAB;
    }
`

export const BorderedTextButtonStyle = `
    text-align: center;
    font-size: ${theme.heading4.fontSize};
    font-weight: ${theme.heading4.fontWeight};
    padding: 4px 15px;
    border-radius: 15px;
`