import React, { Component } from "react";
import {
    FilterWrapper,
    FilterLabel,
    FilterIcon,
    OptionsMenu,
    OptionItem
} from "./style";
import FilterInput from "./FilterInput";
import { connect } from "react-redux";
import { filterActions } from 'store/Home/filterReducer';
import { updateOptions } from 'store/Home/optionReducer';
import { PORT, PATH, MODE } from "services/api";
import { BarLoader } from "react-spinners";
import { getEncryptObj } from "utils/encrypt";
import { AuthContext } from '../../../contexts/AuthContext';
import { truncate } from "utils/Function/string";




class Filter extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            filterState: false,
            loading: false,
        };

        this.handleFilterSelect = this.handleFilterSelect.bind(this);
        this.handleFilterDeselect = this.handleFilterDeselect.bind(this);
    };

    handleFilterSelect = (key, value) => {
        this.setState(() => ({
            filterState: false
        }), () => {
            this.props.changeFilter({ key: key, value: value});
        });
    };

    handleFilterDeselect = (key) => {
        this.setState(() => ({
            filterState: false
        }), () => {
            this.props.deselectFilter({ key: key });
        });
    };

    changeFilterState = () => {
        // this.setState((prevState) => ({
        //     filterState: !prevState.filterState,
        // }), () => {
        //     if (this.state.filterState === false) {
        //         //Over Here We will call SetTimeOut only if there is a change in filterState;
        //         if (this.props.ifSelected)
        //             this.props.setTimeOutVaccine();
        //         console.log('True')
        //     } else {
        //         this.props.clearTimeOutVaccine();
        //     }
        // });
        this.setState((prevState) => ({
            filterState: !prevState.filterState,
        }),
        () => {
            if (this.state.filterState)
                this.getOptions();
        });
    };

    close = () => {
        this.setState(() => ({
            filterState: false,
        }));
    };

    componentDidUpdate() {
        const { filterState } = this.state;

        setTimeout(() => {
            if (filterState) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0);
    };

    getOptions = (id) => {
        this.setState(
            () => ({
                loading: true,
            }), 
            async () => {
                
                try {
                    let filters = JSON.parse(JSON.stringify(this.props.filters));
                    filters[this.props.filterName] = "";
                    filters["search"] = this.props.searchText;
                    const filtersString = JSON.stringify(filters);
                    const encryptObj = await getEncryptObj(filtersString);
                    const rawResponse = await fetch(
                        `${MODE}://${PATH}:${PORT}/v1/filter?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                "Authorization": `${this.context.currentUser}`
                            }
                        });

                    if (!rawResponse.ok && rawResponse.status === 403) {
                        this.context.userLogOut();
                    }

                    // Receive filtered results
                    const results = await rawResponse.json();

                    this.handleOptionUpdate(this.props.filterName, results[this.props.filterName]);

                    this.setState({
                        loading: false
                    });
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                }
        });
    };

    handleOptionUpdate = (option, value) => {
        if (value != null) {
            this.props.updateOptions({optionKey: option, optionValue: value});
        }
    };

    optionRemoveMap = new Map([
        ["sort", "No Order"],
        ["state", "All States"],
        ["city", "All Cities"],
        ["size", "All Sizes"],
        ["type", "All Types"],
        ["focusAreas", "All Focus Areas"],
        // ["Technology", "All Technology"],
        // ["Organisation_Type", "All Organisation Type"],
        // ["Organisation_Size", "All Organisation Sizes"],
        ["company_received_funding", "All Funding Received"],
        ["status", "All Organisations"]
    ]);

    render = () => {
        const { filterLabel, filterName, filterValue, options } = this.props;

        const ifOpen = this.state.filterState, ifApplied = filterValue !== "";

        return (
            <FilterWrapper ifOpen={ifOpen} ifApplied={ifApplied}>
                <FilterLabel onClick={this.changeFilterState}>
                    <FilterInput 
                        filterLabel={filterLabel}
                        filterValue={filterValue}
                    />
                    <FilterIcon ifOpen={ifOpen} ifApplied={ifApplied}/>
                </FilterLabel>
                <OptionsMenu>
                    { this.state.loading 
                        ? <BarLoader
                                color="#381650"
                                height={5}
                                speedMultiplier={0.8}
                                width={80}
                                cssOverride={{ margin: "2vh 0px 2vh 1vw" }}
                            />
                        :  this.getFilterMenu(filterName, options, ifApplied)
                    }
                </OptionsMenu>
            </FilterWrapper>
        )
    };

    getFilterMenu = (filterName, options, ifApplied) => {
        return (
            <>
                { (ifApplied) && <OptionItem
                    key="cancel-option"
                    onClick={() => this.handleFilterDeselect(filterName)}
                    ifApplied={ifApplied}
                    ifSelectable={true}
                >
                    <option style={{height: "10px", width: "100px"}}>{truncate(this.optionRemoveMap.get(filterName), 50)}</option>
                </OptionItem> }
                { this.getFilterOptions(filterName, options, ifApplied) }
            </>
        )
    }

    getFilterOptions = (name, options, ifApplied) => {
        return options.map((entry) => {
            const [ option, selectable ] = entry;
            const ifSelectable = Boolean(selectable);
            return (
                <OptionItem
                    key={option}
                    onClick={
                        ifSelectable ?
                            () => this.handleFilterSelect(name, option)
                            : undefined
                    }
                    ifApplied={ifApplied}
                    ifSelectable={ifSelectable}
                > 
                    {/* <option value={option}>
                        {option}
                    </option> */}
                    { option }
                </OptionItem>
            )
        });
    };
};

const mapStateToProps = state => {
    return {
        filters: state.homeFilter.filters,
        filterOptions: state.homeOptions.options,
        searchText: state.homeSearch.searchText,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
        deselectFilter:(obj) => dispatch(filterActions.deselectFilter(obj)),
        clearOptions: (obj) => dispatch(updateOptions.clearOptions(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);