import { createSlice } from '@reduxjs/toolkit';

export const defaultState = {
    tabs: {
        focusArea: "1",
        geographicalRegion: "0",
        wiseRMatch: "2",
        jlabsFamilyCompanies: "2"
    },
};

export const tabs = createSlice({
    name: 'header/tabs',
    initialState: defaultState,
    reducers: {
        selectTab: (state, action) => {
            Object.keys(state.tabs).map((value => {
                if(state.tabs[value] != "2")
                    state.tabs[value] = "0";
                else
                    state.tabs[value] = "2";
            }))
            state.tabs[action.payload.Tab] = "1";
        }
    }
})

export const tabsActions = tabs.actions;
export default tabs.reducer;