import styled from "styled-components";
import { media } from 'utils/Static/index';

export const PopupBackdropContainer = styled.div`
    position: fixed;
    z-index: ${props => props.zIndex ? props.zIndex : `20`};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #6C7073;
    opacity: 0.7;

    backdrop-filter: blur(10px);

    display: ${props=> props.isDisplay ? 'block' : 'none'};
`

export const LoaderModal = styled.div`
    position: fixed;
    left: 50%;
    top: 25%;

    width: 22%;
    height: 24%;

    z-index: 20;

    background-color: #FAFAF9;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 24px;

    color: #468AAB;

    padding: 2vh;

    transform: translateX(-50%);

    display: ${props => props.isDisplay ? 'flex' : 'none'};

    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.6vh;
    
`

export const LoadingImage = styled.img`
    width: 120px;
    height: 120px;

    animation: rotation 2s infinite linear;

    @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
`