import React, { Component } from "react";
import { FooterContainer, FooterHeading, FooterLink, FooterLinkSeparator, LinkContainer } from "./style.footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "assets/image/wiser-logo.png";


class Footer extends Component {
    render() {
        return (
            <FooterContainer relative={this.props.relative}>
                {/* Footer: Company & copyright information */}
                <FooterHeading>
                    <LazyLoadImage id="org-logo" src={Logo} alt="Logo" />
                    GLOBAL ACTION ALLIANCE&nbsp;&nbsp;|&nbsp;&nbsp;LifeScience WISE-R &#8482; a Global Action Alliance Platform
                </FooterHeading>
                <LinkContainer>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        About GAA
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Privacy Policy
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Terms and Conditions
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink target="_blank" rel="noreferrer" className="disabled">
                        Located in Washington DC
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink href="mailto:hansilla.alaigh@globalactionalliance.net">
                        Contact Us at contact@globalactionalliance.net
                    </FooterLink>
                    <FooterLinkSeparator>|</FooterLinkSeparator>
                    <FooterLink href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">
                        Share feedback
                    </FooterLink>
                </LinkContainer>
            </FooterContainer>
        )
    }
}

export default Footer;