import styled from "styled-components";
import { media } from 'utils/Static/index';

//-------- Beradcrumb--------------

export const Breadcrumb = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: 0.57vh 0 0vh 0;
    
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontSize};
    font-style: normal;

    span:first-child{
        margin-left: 0;
    }
`
export const Link = styled.span`
    margin-left: 5px;
    color: #584A9F;
    cursor: pointer;

    &::after{
        content : '>';
        margin-left: 5px; 
    }
`

export const Typography = styled.span`
    margin-left: 5px;
    
    color: #381650;
    cursor: pointer;
`