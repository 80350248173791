import { createSlice } from '@reduxjs/toolkit';


const defaultState = {
    options: {
        sort: [
            ["Alphabetical", true], 
            ["Chronological", true], 
            ["Size", true],
        ],
        state: [],
        city: [],
        size: [],
        type: [],
        focusAreas: [],
        // Technology: [],
        // Organisation_Type: [],
        // Organisation_Size: [],
        company_received_funding: [],
        status: [],
    }
};

const options = createSlice({
    name: 'option/home',
    initialState: defaultState,
    reducers: {
        updateOptions: (state, action) => {
            state.options[action.payload.optionKey] = action.payload.optionValue;
        },

        clearOptions: (state, action) => {
            state.options[action.payload.key] = "";
        }
    }
})


export const { updateOptions, clearOptions } = options.actions;

export default options.reducer;