import styled from "styled-components";
import { gridItem, viewMoreStyle, media} from 'utils/Static/index';


//--------------Manufacturer Page---------------


export const HomeDetailContainer = styled.section`
    width:100%;
    height: 100%;
    min-height: 100vh;
    
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    display: flex;
    flex-direction: column;
`

export const HomeGrid = styled.main`
    flex-grow: 1;
    margin: 0.5vh 1.4vw 0;
    height: 72vh;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(15, 4.4%);
    gap: 1.9vh;
`
export const About = styled.section`
    ${gridItem}
    grid-column: 1 / 6;
    grid-row: 1 / 10;
`

export const AboutContent = styled.p`
    margin: 0 4%;
    font-family: 'Libre Franklin';

    font-weight: ${props => props.theme.bodyText.fontWeight};
    font-size: ${props => props.theme.bodyText.fontSize};

    color: #381650;
    
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`

export const AboutContentBottomBlur = styled.div`
    position: absolute; 
    bottom: 0%;
    width: 100%; 
    height: 17.15%; 
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
`

export const Contacts = styled.section`
    ${gridItem}

    grid-column: 6 / 11;
    grid-row: 5 / 11;
`

export const ManufactureNews = styled.section`
    ${gridItem}

    grid-column: 1 / 6;
    grid-row: 10 / 15;
`

export const NewsContainer = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    gap: 2px;
    width: 95%;
`

export const NewsBlock = styled.div`
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const NewsInfo = styled.div`
    border-bottom: 2px solid #C4C4C4; 
    width: auto;
    flex-grow: 1;

    & P{
        margin: 8px 0;
    }

    & p:first-of-type{
        color: #6C7073;
        font-size: ${props => props.theme.heading6.fontSize};
    }

    & p:last-of-type{
        color: #5C2583;
        font-size: ${props => props.theme.heading4.fontSize};
    }
`
export const NewsButton = styled.button`
    background: none;
    width: 24%;
    height: 50%;
    color: #5C2583;
    border-radius: 40px;
    border: 1px solid #5C2583;
`

export const ManufactureLicensed = styled.section`
    ${gridItem}

    grid-column: 6 / 11;
    grid-row: 1 / 5;
`

export const TotalGovernmentFunding = styled.section`
    ${gridItem}

    grid-column: 1 / 6;
    grid-row: 10 / 13;
`

export const TotalPrivateFunding = styled.section`
    ${gridItem}

    grid-column: 1 / 6;
    grid-row: 13 / 16;
`

export const TotalFundingAmountHomeD = styled.div`
    box-sizing: border-box;
    width: 80%;
    margin: auto 0;
    padding: 1vh;
    background-color: #5C2583;
    color: #FFFFFF;
    text-align: center;

    font-size: ${props => props.theme.heading2.fontSize};
    font-weight: ${props => props.theme.heading2.fontWeight};
`

export const ManufactureMaps = styled.section`
    ${gridItem}

    grid-column: 6 / 11;
    grid-row: 11 / 16;
`

export const ManufactureFunding = styled.section`
    ${gridItem}

    grid-column: 11 / 17;
    grid-row: 1 / 7;
`



export const TotalFundingAmount = styled.div`
    width: max-content;
    margin: auto 0;
    padding: 1vh 1vw;
    background-color: #5C2583;
    color: white;
    border-radius: 8px;
    text-align: center;

    font-size: ${props => props.theme.heading1.fontSize};
    /* text-align: center; */
`

export const ManufactureTrials = styled.section`
    ${gridItem}

    grid-column: 11 / 17;
    grid-row: 7 / 16;
`

export const ManufactureViewMore = styled.button`
    ${viewMoreStyle}

    grid-column: 11 / 17;
    grid-row: 13 / 15;

    ${props => props.disabled ? ' \
        color: #C4C4C4; \
        border: 1px solid #C4C4C4; \
        pointer-events: none; \
    ' : ''
    }
`

export const TableContainerLicensed = styled.table`
    width: 100%;
    /* height: 86%; */
    box-sizing: border-box;
    height: ${props => props.calcHeight ? '100%' : '60%'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;

    & td {
        border: 1px solid #DED3E6;
    }

`

export const TableContainerCompanyInfo = styled.table`
    width: 100%;
    /* height: 86%; */
    box-sizing: border-box;
    height: ${props => props.calcHeight ? '100%' : '22vh'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;

    & td{
        border: 1px solid #DED3E6;
    }

`

export const TableContainer = styled.table`
    width: 100%;
    /* height: 86%; */
    box-sizing: border-box;
    height: ${props => props.calcHeight ? '100%' : '32.5vh'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;

    & td {
        border: 1px solid #DED3E6;
    }

`
export const TableContainer2 = styled.table`
    width: 100%;
    /* height: 86%; */
    box-sizing: border-box;
    height: ${props => props.calcHeight ? '100%' : '100%'};

    overflow: hidden;
    border-radius: 4px;
    border-collapse: collapse;

    & td{
        border: 1px solid #DED3E6;
    }

`


export const TableHead = styled.thead`
    width: 100%;

    & tr {
        background-color: #5C2583;
        color: #FAFAF9;
        text-align: center;
        width: fit-content;
    }

    & tr > th {
        padding: .2vh .4vw;
        font-weight: normal;
        white-space: nowrap;
    }

    & tr > th:last-of-type {
        text-align: left;
    }
`

export const TableBody = styled.tbody`
    & tr {
        text-align: center;
        color: #164050;
    }

    & tr > td:last-of-type {
        text-align: left;
    }

    & tr > td {
        padding: .3vh .5vw;
        font-weight: normal; 
    }

    & tr:nth-of-type(even) {
        background-color: #DED3E6;
    }

    & a {
        text-decoration: inherit;
        color: inherit;
        cursor: auto;
        color: #23647C;
    }

    & a:hover {
        color: #468AAB;
        cursor: pointer;
    }
`

export const TableHeadLicensed = styled.thead`
    width:100%;

    & tr {
        background-color: #5C2583;
        color: #FAFAF9;
        text-align: center;
    }

    & tr > th {
        padding: .55vh 0;
        font-size: ${props => props.theme.bodyText.fontSize};
        font-weight: ${props => props.theme.bodyText.fontWeight};
        white-space: nowrap;
    }

    & tr > th:first-of-type {
        padding: .55vh 4%;
        text-align: left;
    }
`

export const TableBodyByLicensed = styled.tbody`
    & tr {
        text-align: center;
        color: #164050;
    }

    & tr > td:first-of-type {
        text-align: left;
    }

    & tr > td {
        padding: .55vh 4%;
        font-size: ${props => props.theme.bodyText.fontSize};
        font-weight: ${props => props.theme.bodyText.fontWeight};
    }


    & tr:nth-of-type(even){
        background-color: #DED3E6;
    }
`

export const TableDataDiv = styled.div`
    color:#5C2583;
    /* color:#381650; */
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

export const JlabsStatus = styled.div`
    color: #381650;
    /* color:#381650; */
    width: auto;
    padding: 0.4vh .2vw;

    background: #E8E0ED;
    border-radius: 4px;
`

export const FocueAreacContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1vh .7vw;
`

export const FocueArea = styled.span`
    padding: 1vh 1.6vw;
    height: fit-content;
    
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 200;
    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};

    color: #5C2583;

    background: #E8E0ED;
    border-radius: 15px;
`