import { PaginationWrapper, PageClick} from './style.pagination';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

const ComponentTablePagination = (props) => {


    return(
        <PaginationWrapper
            height = {props.height}
        >
            {props.numberOfPage != 1 && <PageClick disabled={props.currentPage === 1} onClick={() => { props.handlePageClick(props.currentPage - 1) }}><BsFillCaretLeftFill /></PageClick>}
            <div style={{flexGrow: '1', alignSelf:`${!props.center && 'flex-start'}`}}>{props.children}</div>
            {props.numberOfPage != 1 && <PageClick disabled={props.currentPage == props.numberOfPage} onClick={() => { props.handlePageClick(props.currentPage + 1) }}><BsFillCaretRightFill /></PageClick>}
        </PaginationWrapper>
    );
}

export default ComponentTablePagination;