import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import { PORT, PATH, MODE } from "services/api";
import { useAuth } from "contexts/AuthContext";
import { Dropdown } from "components"
import { 
    HomePage,
    GreetingsArea,
    GreetingsHeading,
    GreetingsSubHeading,
    InfoArea, 
    InfoInput, 
    SolidButton, 
    InfoTitleContainer,
    InfoTitleHeading, 
    InfoTitleSubHeading,
    OptionsContainer,
    LinkTextButton,
    TitleContainer, 
    JlabsLogo,
    WiserLogo,
    InputContainer, 
    LoginLogo} from './style.Auth';

import { Footer, Password, Error, RememberMeCheckbox } from "components";
import { validateField } from "./utils/Validator";

import { useDispatch } from 'react-redux';
import { userLogin } from 'store/Requests/auth'; 

import ProductTitle from "assets/image/logo.png";
import ProductLogo from "assets/image/wiser-logo.png";

export default function LogInForm() {

    const { userSignIn } = useAuth();
    const navigate = useNavigate();
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const loginButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const initialState = {
        email: "",
        password: "",
        location: "Office",
        rememberMe: false,
    };

    const [ data, setData ] = React.useState(initialState);
    const [ isSubmitting, setIsSubmitting ] = React.useState(false);
    const [ focusedFields, setFocusedFields ] = React.useState({
        email: false,
        password: false,
    });

    const [ errorMessages, setErrorMessages ] = React.useState({ 
        "areValid": true,
        "errors": {
            "email": { 
                isValid: true, 
                error: null
            },
            "password": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleIconClick = () => {
        return window.open("https://www.globalactionalliance.net/", "_blank")
    }

    const handleInputChange = (label, event) => {
        setData({
            ...data,
            [label]: event.target.value
        });
    }

    const handleFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
    }

    const validateInputFields = (emailRef, pwdRef) => {
        
        let output = { areValid: true, errors: ""}

        const emailValidationResult = validateField("email", [emailRef]);
        const pwdValidationResult = validateField("password", [pwdRef]);


        if (emailValidationResult.isValid && pwdValidationResult.isValid) {
            output = { 
                areValid: true, 
                errors: { 
                    "email": emailValidationResult, 
                    "password": pwdValidationResult 
                }}
        } else {
            output = { 
                areValid: false, 
                errors: { 
                    "email": emailValidationResult, 
                    "password": pwdValidationResult 
                }}
        }

        return output;
    }
    useEffect(()=>{
        if (didMountRef.current) {
            const areInputFieldsValid = validateInputFields(emailInputRef, passwordInputRef);
            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: areInputFieldsValid.areValid,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: areInputFieldsValid.errors.email.isValid, 
                            error: areInputFieldsValid.errors.email.error},
                        password: { 
                            ...errorMessages.errors.password, 
                            isValid: areInputFieldsValid.errors.password.isValid, 
                            error: areInputFieldsValid.errors.password.error},
                    }
                });
            } else {
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: true, 
                            error: null},
                        password: { 
                            ...errorMessages.errors.password, 
                            isValid: true, 
                            error: null},
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            }
        } else {
            didMountRef.current = true;
            loginButtonRef.current.disabled = true;
        }
    },[data]);

    const handleSubmit = () => {

        if(isSubmitting == false)
            return;

        dispatch(
            userLogin({email: data.email, 
                password: data.password, 
                shouldRememberMe: data.rememberMe}
            )
        )
        .unwrap()
        .then(resJson => {
            userSignIn(resJson.token);
            navigate("/");
        })
        .catch(error => {
            console.log(error);
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        });
        
    }

    const toggleRememberMe = () => {
        setData({
            ...data,
            rememberMe: !data.rememberMe,
        });
    }

    const handleDropdownValueChange = (value) => {
        setData({ ...data, location: value});
    }


    const dropdownOptions = new Map([
        ["Washington DC", true], 
        ["North California", false], 
        ["South California", false],
        ["Virginia", false],
        ["Maryland", false],
        ["Delaware", false]
    ]);

    return (
        <HomePage>
            <GreetingsArea>
                <TitleContainer onClick={handleIconClick}>
                    <JlabsLogo id="org-title" src={ProductTitle} alt="JLabs" />
                </TitleContainer>
                <GreetingsHeading>
                    Welcome to 
                    <br/>
                    your dashboard
                </GreetingsHeading>
                <GreetingsSubHeading>
                    An AI life science platform that aggregates, analyzes & visualizes data to provide limitless dynamic insight from any functional perspective and level of detail
                </GreetingsSubHeading>
            </GreetingsArea>

            <InfoArea>
                <InfoTitleContainer>
                    <LoginLogo>
                        <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" />
                        <InfoTitleHeading>Log in</InfoTitleHeading>
                    </LoginLogo>
                    <Dropdown 
                        name={"Office"}
                        placeholder={"Office"}
                        options={dropdownOptions}
                        value={data.location}
                        handleDropdownValueChange={handleDropdownValueChange}
                    />
                </InfoTitleContainer>
                <InfoTitleSubHeading>
                    This is a secure system. Please enter company credentials in order to access your dashboard.
                </InfoTitleSubHeading>
                <InputContainer>
                    <InfoInput
                        name={"name"}
                        type={"email"}
                        value={data.email}
                        ref={emailInputRef}
                        required={true}
                        className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleInputChange("email", e)}}
                        onFocusCallback={(e) => {handleFocusChange("email", true)}}
                        onBlurCallback={(e) => {handleFocusChange("email", false)}}
                        placeholder="Company email ID"
                        keyDownCallback={handleSubmit}
                    />  
                    <Error 
                        isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email) }
                        message={ errorMessages.errors.email.error }
                    />

                    <Password
                        value={data.password}
                        placeholder="Password"
                        required={true}
                        innerRef={ passwordInputRef }
                        className={ errorMessages.errors.password.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleInputChange("password", e)}}
                        onFocusCallback={(e) => {handleFocusChange("password", true)}}
                        onBlurCallback={(e) => {handleFocusChange("password", false)}}
                        keyDownCallback={handleSubmit}
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.password.isValid || focusedFields.password)}
                        message={ errorMessages.errors.password.error }
                    />

                </InputContainer>
                <OptionsContainer>
                    <RememberMeCheckbox
                        value={data.rememberMe}
                        handleChange={toggleRememberMe} 
                    />
                    {/* //TODO: Complete the "Forgot password" functionality
                    <LinkTextButton
                        onClick={() => navigate('/')}
                    >
                        Forgot password?
                    </LinkTextButton> */}
                </OptionsContainer>
                <Error 
                    isVisible={ !errorMessages.errors.others.isValid }
                    message={ errorMessages.errors.others.error }
                />
                
                <SolidButton
                    type="button"
                    ref={loginButtonRef}
                    onClick={handleSubmit} 
                    style={{marginTop: "2.7vh"}}
                    disabled={ isSubmitting ? false : true }
                >
                    Log in
                </SolidButton>
                
                <LinkTextButton 
                    onClick={() => navigate('/signup')}
                    style={{marginTop: "1.5vh"}}
                >
                    First time? Sign up instead
                </LinkTextButton>
            </InfoArea>
            <Footer />
        </HomePage>
    )
};