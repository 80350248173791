import styled from "styled-components";
import { homeInputStyle } from 'utils/Static/index';
import { media } from "utils/Static/index";

import DownUnselectedIcon from "assets/image/down-arrow-teal.png";
import DownSelectedIcon from "assets/image/down-arrow-white.png";
import UpUnselectedIcon from "assets/image/up-arrow-teal.png";
import UpSelectedIcon from "assets/image/up-arrow-white.png";


export const DropdownsContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-start;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
`

export const DropdownWrapper = styled.div`
    position: absolute;
    right: 10%;
    top: 16%;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    /* background: transparent;*/
    /* border-radius: ${props => props.ifOpen ? '1rem' : '1rem'}; */
    border-radius: 1rem;

    max-height: ${props => props.ifOpen ? '34vh' : '3.5vh'};
    width: max(7vw, 20%);
    padding: 0 0.8vw;
    padding-bottom: ${props => props.ifOpen ? '5px' : '0px'};
    background-color: #FAFAF9;
    overflow: hidden;

    transition: max-height 0.2s;

    & input {
        color: ${props => props.theme.colors.text.clickableText_2};
    }

    &:hover {
        background-color: ${props => 
            !props.ifOpen ? '#F1E5FC' : '#FAFAF9'};
        cursor: pointer;
    }
    font-size: 0.8rem;


    ${media.monitor} {
        top: 15%;
    }

    ${media.large_desktop} {
        font-size: 0.7rem;
        top: 13.7%;
    }

    ${media.laptop} {
        top: 13.4%;
    }

    ${media.tablet}{
        top: 13.2%;
    }
    
`

export const DropdownLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    
    border-bottom: 1px solid #5C2583;
`

export const DropdownIcon = styled.img.attrs(({ifApplied, ifOpen}) => ({
    src: 
    ifApplied ? 
        (ifOpen ? UpSelectedIcon : DownSelectedIcon)
        : (ifOpen ? UpUnselectedIcon : DownUnselectedIcon)
}))`
    width: 10px;
    height: 6px;
`

export const DropdownInput = styled.input`
    ${homeInputStyle}
    padding: 0;
    text-align: left;
    width: 80%;
    height: 3.5vh;

    &::placeholder{
        color: inherit
    }
`

export const OptionsMenu = styled.ul`
    list-style: none;
    padding: 10px 0px;
    margin: 0;
    width: 100%;
    max-width: 8.2vw;
    max-height: 25vh;

    ${'' /* border-top: 1px solid #5C2583; */}

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
        margin-top: 0.4em;
    }
`

export const OptionItem = styled.li`
    width: 85%;
    padding: 2px 1px 2px 5px;
    cursor: ${props => props.ifSelectable ? "pointer" : "default"};
    color: ${
        props => (props => props.ifSelectable ? 
            props.theme.colors.text.clickableText_2 : props.theme.colors.text.disabledText)
    };
    overflow-x: hidden;

    &:hover {
        background-color: ${
            props => (props => props.ifSelectable ? "#F1E5FC" : "inherit")
        };
        border-radius: 6px;
    }
`