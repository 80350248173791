import { createSlice } from '@reduxjs/toolkit';
import { userLogin } from '../Requests/auth';

const loginSlice = createSlice({
    name: "login",
    initialState: {
        response: [],
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(userLogin.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.response = action.payload;
        });
        builder.addCase(userLogin.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    }
});


export const authLoginReducer = loginSlice.reducer;