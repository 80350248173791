import { React, Component, createRef } from "react";

// import Logo from "assets/image/logo.png";
import {
    HomeHeader,
    Footer,
    Tags,
    Pagination,
    Loader,
    Input,
    Header,
    ComponentTablePagination
} from "components";

import { 
    CardContainer,
    CardContentWrapper,
    CardContent,
    HomePage,
    LearnMoreButton,
    OrganisationCard,
    SavedButton,
    HeaderContainer,
    SearchFilterContainer,
    OtherTagsWrapper,
    OrganizationWrapper,
    TopScroll,
    TopScrollArrow,
    CardPaginationWrapper,
    CardPageClick
} from "./style.home";

import { BiSearch } from "react-icons/bi";

import {SearchBar, SubmitBtn} from "components/HomeHeader/style.homeHeader";
import { ContentContainer } from "pages/style";

import { connect } from "react-redux";
import { updateOptions } from 'store/Home/optionReducer';
import { filterActions } from "store/Home/filterReducer";
import { filterActions as searchTextActions } from "store/Home/searchTextReducer";
import { analyticsAction } from "store/Header/headerReducer";

import FiltersRow from './filters/FiltersRow';

import withRouter from "utils/ComponentWrapper";
import { getHomeData } from "store/Requests/home";

import { getEncryptObj } from "utils/encrypt";

import { AuthContext } from '../../contexts/AuthContext';
import { BsTriangleFill } from "react-icons/bs";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";



const JLABS_STATUS_COLOR_MAP = {
    "Current": "#C5E48C",
    "Alumni": "#F1E9A3",
    "Declined": "#F99585",
    "In Process": "#F0F0EF"
}


class Home extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            totalPage: 0,
            currentPage: 1,
            ifSelected: false,
            searchText: "",
            isHeaderCollapsed: false,
        };
        this.topContentRef = createRef();
        this.updateCardsDescriptionMaxLines = this.updateCardsDescriptionMaxLines.bind(this);
        this.calcCompanyNameHeadingLineCount = this.calcCompanyNameHeadingLineCount.bind(this);
        this.paginate = this.paginate.bind(this);
    };

    queryFilteredData = (newPage = this.state.currentPage) => {

        const pageToQuery = (newPage == this.state.currentPage ? 1 : newPage);

        this.setState(
            () => ({
                loading: true,
                searchText: this.props.searchText,
            }),
            async () => {
                try {
                    let filters = JSON.parse(JSON.stringify(this.props.filters));
                    filters["search"] = this.state.searchText;

                    const dataString = JSON.stringify({
                        filters: filters,
                        pageNum: pageToQuery,
                    });
                    const encryptObj = await getEncryptObj(dataString);
                    
                    this.props.getHomeData({ encryptObj: encryptObj, currentUser: this.context.currentUser })
                    .unwrap()
                    .then(rawResponse => {
                        if(rawResponse.ok)
                            return rawResponse.json();
                    
                        if (!rawResponse.ok && rawResponse.status === 403) {
                            this.context.userLogOut();
                        }
                    })
                    .then(results => {
                        // Update Home options
                        // this.handleOptionUpdate("city", results.options.city);
                        // this.handleOptionUpdate("state", results.options.state);
                        // this.handleOptionUpdate("size", results.options.size);
                        // this.handleOptionUpdate("type", results.options.type);
                        // this.handleOptionUpdate("focusArea", results.options.focusAreas);
                        const newAnalytics = results.analytics;
                        this.handleAnalyticsUpdate("totalCompanies", newAnalytics.totalCompanies);
                        this.handleAnalyticsUpdate("receivedPrivateFunding", newAnalytics.receivedPrivateFunding);
                        this.handleAnalyticsUpdate("receivedGovernmentFunding", newAnalytics.receivedGovernmentFunding);
                        this.handleAnalyticsUpdate("companyByFocusArea", newAnalytics.companyByFocusArea);
                        this.handleAnalyticsUpdate("activePerid", newAnalytics.activePeriod);
                        this.handleAnalyticsUpdate("companyByState", newAnalytics.companyByState);
                        this.handleAnalyticsUpdate("fundingByState", newAnalytics.fundingByState);
                        this.handleAnalyticsUpdate("mapData", newAnalytics.mapData);


                        this.setState(() => ({
                            data: results.content,
                            totalPage: results.totalPageCount,
                            currentPage: pageToQuery,
                            loading: false,
                        }));
                    })
                    .catch(error => {
                        console.error(`Error Occurred during fetch: ${error}`);
                    })
                    
                } catch (err) {
                    console.error(`Error Occurred before fetch: ${err.message}`);
                }
            }
        )
    }


    handleAnalyticsUpdate = (option, value) => {
        this.props.updateAnalytics({key: option, value: value});
    };

    handleOptionUpdate = (option, value) => {
        this.props.updateOptions({optionKey: option, optionValue: value});
    };

    componentDidMount = () => {
        this.queryFilteredData();
    };

    scrollTopContent = () => {
        this.topContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    paginate = (number) => {
        this.queryFilteredData(number);

        this.scrollTopContent();
    };



    // Should be implemented in Redux Thunk later
    componentDidUpdate = (prevState) => {
        this.updateCardsDescriptionMaxLines();
        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevState.filters)) {
            this.setState(
                () => (
                    { ifSelected: true }
                ));
            this.fetchCall();
        }
    };


    fetchCall = () => {
        this.timeOut = setTimeout(() => {
            
            this.queryFilteredData();
            this.setState(
                () => (
                    { ifSelected: false }
                ));
        }, 0);
    };

    clickOrganisationCard = (e) => {
        const orgName = e.replace("/", "%2F");
        this.props.history(`/${orgName}`);
    }

    handleSearchInput = (e) => {
        e.preventDefault();
        this.setState(() => ({ searchText: e.target.value }), () => {
            this.props.updateSearch({ newValue: this.state.searchText });
        });
        
    }

    handleSearchBarFocusCallback = (e) => {
        this.setState(() => ( { isHeaderCollapsed: true } ));
        window.scrollTo(0, 0);
    }

    render =()=> {
        return (
            <HomePage >
                <Loader isVisible={this.state.loading}/>
                <HeaderContainer isHeaderCollapsed={this.state.isHeaderCollapsed}>
                    {
                        this.state.isHeaderCollapsed
                        ? <Header 
                            history={this.props.history} 
                            isHome={true} 
                            onBackClickCallback={()=> this.setState(() => ( { isHeaderCollapsed: false } ))}
                        />
                        : <HomeHeader
                            history={this.props.history}
                            isHeaderCollapsed={this.state.isHeaderCollapsed}
                            // searchText={this.state.searchText}
                            // submitCallback={this.queryFilteredData}
                            // handleSearchInput={this.handleSearchInput}
                            isHome={false}
                        />
                    }

                    <SearchFilterContainer isHeaderCollapsed={this.state.isHeaderCollapsed}>
                        <SearchBar isHeaderCollapsed={this.state.isHeaderCollapsed}>
                            <Input
                                value={this.state.searchText}
                                onFocusCallback={this.handleSearchBarFocusCallback}
                                onChangeCallback={this.handleSearchInput}
                                placeholder="Search by Organization name or Keyword"
                                keyDownCallback={() => this.queryFilteredData(1)}
                            />
                            <SubmitBtn
                                onClick={() => this.queryFilteredData(1)}
                                id="submit-btn"
                            >
                                <BiSearch />
                                <span>Search</span>
                            </SubmitBtn>
                        </SearchBar>

                        <FiltersRow
                            routes={this.props.history}
                            ifSelected = {this.state.ifSelected}
                        />
                    </SearchFilterContainer>
                </HeaderContainer>

                <TopScroll onClick={this.scrollTopContent}>
                    <BsTriangleFill/>
                </TopScroll>

                <ContentContainer
                    isHeaderCollapsed={this.state.isHeaderCollapsed}
                    ref={this.topContentRef}
                >
                    {
                        this.state.totalPage > 1 ?
                            <CardPaginationWrapper>
                                <CardContainer>
                                    {this.generateOrganisationCard()}
                                </CardContainer>
                            </CardPaginationWrapper>
                            :
                            <CardContainer>
                                {this.generateOrganisationCard()}
                            </CardContainer>
                    }

                    <Pagination
                        numberOfPages={this.state.totalPage}
                        currentPage={this.state.currentPage}
                        paginate={this.paginate}
                    />
                </ContentContainer>
                
                <Footer relative/>
            </HomePage>
        );
    };

    //---------Render Helper Functions----------

    updateCardsDescriptionMaxLines = () => {
        const cardHeadings = document.querySelectorAll('[id^="Company Name"]');
        const cardContents = document.querySelectorAll('[id^="Card Content"]');
        for( let i=0; i<cardHeadings.length; i++) {
            const maxLines = this.calcCompanyNameHeadingLineCount(cardHeadings[i]) === 1 ? 5 : 7;
            cardContents[i].style.maxHeight = (1.2 * maxLines) + "em";
            cardContents[i].style.webkitLineClamp = maxLines;
        }
    }


    calcCompanyNameHeadingLineCount = (headingRef) => {
        const headingHeight= +headingRef.offsetHeight;
        const defaultFontSize = 30;
        const headingLineHeight = +(headingRef.style?.lineHeight.replace('em','') * headingRef.style.fontSize.replace('rem','')) * defaultFontSize;
        
        const lineCount = Math.floor(headingHeight / headingLineHeight);
        return lineCount;
    }

    generateOrganisationCard = () => {
        return this.state.data.length > 0 ? 
        (
            this.state.data.map((item, index) => {
                return (
                    <OrganisationCard
                        key={index}
                        isGrow = {this.state.data.length > 4}
                    >
                        <CardContentWrapper>
                            <div 
                                id={"Company Name- " + index}
                                style={{ fontSize: '0.75rem', fontWeight: '500', lineHeight: '1.2em'}}>
                                {item.Company_Name}
                            </div>
                            <p>{item.Company_Type ?? <span>&nbsp;</span>}</p>
                            <div style={{height: '125px'}}>
                                <CardContent
                                    id={"Card Content- " + index}>
                                    {item.Description}
                                </CardContent>
                            </div>
                        </CardContentWrapper>
                        {getJLabsOrgTag(item.Jlabs_org)}
                        <OtherTagsWrapper>
                            <OrganizationWrapper>
                                {getLocationTags(item.Location)}
                            </OrganizationWrapper>
                            {getOtherTags(item)}
                        </OtherTagsWrapper>
                        <div style={{ width: '100%', display: 'flex', gap: '.5vw'}}>
                            {/* <SavedButton>
                                + Saved
                            </SavedButton> */}
                            <LearnMoreButton
                                onClick={() => this.clickOrganisationCard(`${item.Company_Name}`)}
                            >
                                Learn More
                            </LearnMoreButton>
                        </div>
                    </OrganisationCard>
                )
            })
        ) : (
            <h1 style={{color: '#827988', width: '100%', textAlign: 'center'}}>
                No data Present at the moment
            </h1>
        )
    }
}


const getJLabsOrgTag = (status) => {
    return (
        <Tags 
            data={status} 
            tagsKey={"JLABS Status"}
            {...(status === "None" ? {} : {color: JLABS_STATUS_COLOR_MAP[status]})}
        />
    )
}

const getLocationTags = (locations) => {
    // const tagsList = getLocationTagsList(locations);
    const tagsList = [[locations["state/province"], "Location"]];
    return tagsList.map((tagItem, index) => {
        return <Tags key={index} data={tagItem[0]} tagsKey={tagItem[1]}/> ;
    });
};

// Helper function to generate location tags row
// Disabled for now for location object type change
const getLocationTagsList = (locations) => {
    let tagsList = [];
    const numOfLocations = locations.length;

    switch (numOfLocations) {
        case 0:
            break;
        case 1:
            tagsList.push([locations[0]["city"], "Location"]);
            break;
        case 2:
            tagsList.push([locations[0]["city"], "Location"]);
            tagsList.push([locations[1]["city"], ""]);
            break;
        default:
            tagsList.push([locations[0]["city"], "Location"]);
            tagsList.push([locations[1]["city"], ""]);
            tagsList.push([`${numOfLocations-2} more`, ""]);
            break;
    }

    return tagsList;
};

const getOtherTags = (item) => {
    const tagsList = getOtherTagsList(item);
    return tagsList.map((tagItem, index) => {
        return <Tags key={index} data={tagItem[0]} tagsKey={tagItem[1]}/> ;
    });
};

const getOtherTagsList = (item) => {
    let tagsList = [
        [item.Company_Size, "Size"]
    ];
    let cardSpace = 3;
    if (cardSpace > 0 && item.JLABS_Org) {
        tagsList.push([item.JLABS_Org, "Jlabs org"]);
        cardSpace -= 1;
    }
    if (cardSpace > 0 && item.Vaccines_Count && item.Vaccines_Count > 0) {
        tagsList.push([item.Vaccines_Count, "Vaccines by"]);
        cardSpace -= 1;
    }
    if (cardSpace > 0 && item.ctCount != null) {
        tagsList.push([item.ctCount, "Trials by"]);
        cardSpace -= 1;
    }
    return tagsList;
};


const mapStateToProps = (state) => {
    return {
        filters: state.homeFilter.filters,
        searchText: state.homeSearch.searchText,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
        deselectFilter: (obj) => dispatch(filterActions.deselectFilter(obj)),
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
        updateSearch: (obj) => dispatch(searchTextActions.updateSearchText(obj)),
        updateAnalytics: (obj) => dispatch(analyticsAction.updateAnalytics(obj)),
        getHomeData: (obj) => dispatch(getHomeData(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));