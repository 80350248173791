import styled from "styled-components";
import { TextButton } from 'utils/Static/static.css';
import { media } from 'utils/Static/index';

//---------- Common ---------

export const ContentContainer = styled.div`
    ${'' /* padding: 0 1.56vw; */}
    padding: ${props => props.isHeaderCollapsed ? "20vh 1.56vw 0 1.56vw" : "0 1.56vw" };
    height: 100%;
    width: 95%;
    margin: 0 auto;
    min-height: ${props => props.isHeaderCollapsed ? "67vh" : "" };
    display: flex;
    flex-direction: column;
`

export const OverlayContainer = styled.div`
    position: fixed;
    top: 7vh;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background-color: rgba(247, 247, 247, 0.671); /*dim the background*/
    display: ${props => props.display};
    & > .spin-loader {
        margin: 35vh auto 0 auto;
        width: 3vw;
    }
`

export const TitleRowContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: 1.4vw;
`

export const ModalButton = styled.div`
    z-index: '20';
    font-size: '1rem';

    &:hover {
        cursor: pointer;
    }
`

export const TopicTitle = styled.p`
    margin-top: 0.6vh;
    margin-bottom: 1.2vh;
    font-size: ${props=> props.theme.heading3.fontSize};
    font-weight: ${props => props.theme.heading3.fontWeight};
    color: #381650;
`

export const TabSwitch = styled.span`
    width: 19vw;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    padding-right: 1vw;
    border: 1.2px solid #53bbdf;
    color: #53bbdf;
    border-radius: 48px;
    font-size: ${props=> props.theme.heading4.fontSize};
    height: 4vh;
`

export const TabSwitchOn = styled.span`
    width: 7.4vw;
    margin-left: ${props => {
        if (props.marginLeft !== undefined) {
            return props.marginLeft;
        }
        return 0;
    }};
    margin-right: ${props => {
        if (props.marginRight !== undefined) {
            return props.marginRight;
        }
        return 0;
    }};
    margin-top: -1px;
    border: 1.2px solid #53bbdf;
    border-radius: 48px;
    color: white;
    background-color: #53bbdf;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 3.9vh;
`




//--------------Common in only Graph---------

export const GridWrapper = styled.div`
    box-sizing: border-box;
    width: 100%; 
    height: 100%;
    padding: 1.6vh 1.4vw ;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5vh;
`

export const BorderedTextButton = styled.button`
    padding: 0.8vh 0;
    width: 80%;

    color: #23647C;
    background-color: #FAFAF9;
    border: 1.5px solid #468AAB;
    border-radius: 0.8vw;

    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};

    &:hover {
        cursor: pointer;
        color: #FAFAF9;
        background-color: #468AAB;
    }
`

export const WorldMapContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;

    margin: -10px 0 0 0;

    height: 100%;
    width: 100%;
`

export const SummaryTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
`

export const CardOptionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const WoldMapModalContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
    height: 100%;
    width: 100%;

    box-sizing: border-box;
`

export const ShadePlate = styled.div`
    /* flex-shrink: 0; */
    width: 26%;
    align-self: flex-end;
    display: flex;
    margin-right: 1rem;
    margin-top: -3.5vh;
`

export const SummaryTitle = styled.span`
    align-self: flex-start;
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
    color: #381650;
`

export const SummaryTitlePagecount = styled.span`
    font-size: smaller;
`

export const BreadcrumbContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0.6vh 1.4vw;
`

export const ShareData = styled(TextButton)`
    text-decoration: none;
    margin: 0.57vh 0 0 .5vw;
    font-size: ${props=> props.theme.bodyText.fontSize};
`

//----------- Error Log-------

export const NoData = styled.div`
    height: 100%; 
    width: 100%;
    text-align: center;
    color: #808782;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${props=> props.theme.heading3.fontSize};
    font-weight: ${props=> props.theme.heading3.fontSize};

`