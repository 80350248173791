import { createSlice } from '@reduxjs/toolkit';


const defaultState = {
    searchText: ""
};

const searchText = createSlice ({
    name: 'search/home',
    initialState: defaultState,
    reducers: {

        updateSearchText: (state, action) => {
            state.searchText = action.payload.newValue;
        },

        clearSearchText: (state, action) => {
            state.searchText = "";
        },

    }
})

export const filterActions = searchText.actions;
export default searchText.reducer;