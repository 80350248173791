import {useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom'

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const history = useNavigate();
    const location = useLocation();
    // etc... other react-router-dom v6 hooks

    return (
        <WrappedComponent
            {...props}
            params={params}
            history={history}
            location={location}
        />
    );
};

export default withRouter;