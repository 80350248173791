const ENDPOINT = "PRODUCTION" // "LOCAL", "INTERNAL", "PRODUCTION"


function getAPIParams(ENDPOINT) {
    switch (ENDPOINT) {
        case "PRODUCTION":
            return ["https", "jlabs-wiser.net", "443"];
        case "INTERNAL":
            return ["http", "52.203.227.236", "80"];
        case "LOCAL":
            return ["http", "localhost", "8080"];
        default:
            return ["http", "localhost", "8080"];
    }
}


const [ MODE, PATH, PORT ] = getAPIParams(ENDPOINT);

export { MODE, PATH, PORT };
