import { configureStore } from '@reduxjs/toolkit';

import searchTextReducer from 'store/Home/searchTextReducer';
import homeFilterReducer from 'store/Home/filterReducer';
import homeOptionReducer from 'store/Home/optionReducer';
import { homeRequestReducer } from './Home/requestReducer';

import headerTabOptionReducer from 'store/Header/optionReducer';
import headerAnalyticsReducer from 'store/Header/headerReducer'
import { authLoginReducer } from './Auth/loginSlice';
import { authSignUpReducer } from './Auth/signupSlice';
import { authCreateAccountReducer } from './Auth/createAccountSlice';
import { orgRequestReducer } from './HomeDetails/requestReducer';

const store = configureStore ({
    reducer: {
        homeFilter: homeFilterReducer,
        homeOptions: homeOptionReducer,
        homeSearch: searchTextReducer,
        homeRequest: homeRequestReducer,

        headerTabs: headerTabOptionReducer,
        headerAnalytics: headerAnalyticsReducer,
        authLogin: authLoginReducer,
        authSignup: authSignUpReducer,
        authCreateAccount: authCreateAccountReducer,

        orgRequest: orgRequestReducer,
    },
});

export default store;