import { TagsContainer, TagsContent, TagsHeading, TagsContentShort } from './style.tags';

const Tags = ({data, tagsKey="", color="#D7EDF7"}) => {
    // console.log(color)
    return (
        data !== null && data !== undefined ?
            data.length > 30 ?
                <TagsContainer>
                    <TagsHeading>{tagsKey.split('_').join(' ')}</TagsHeading>
                    <TagsContent data-tooltip={data} color={color}>{data.slice(0, 16) + '...'}</TagsContent>
                </TagsContainer>
                :
                <TagsContainer>
                    <TagsHeading>{tagsKey.split('_').join(' ')}</TagsHeading>
                    <TagsContentShort color={color}>{data}</TagsContentShort>
                </TagsContainer>
            : <TagsContainer>
                <TagsHeading>{tagsKey.split('_').join(' ')}</TagsHeading>
                <TagsContentShort color={color}>Unknown</TagsContentShort>
            </TagsContainer>
    );
}

export default Tags;