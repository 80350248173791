import { createSlice } from '@reduxjs/toolkit';

export const defaultState = {
    headerAnalytics: {
        totalCompanies: 1403210,
        receivedPrivateFunding: 5901,
        receivedGovernmentFunding: 6031,
        companyByFocusArea: {
            "Cardiovascular, metabolism & retina": 1402019,
            "Immunology": 1402019,
            "Infectious diseases & vaccines": 1402019,
            "Neuroscience": 1402019,
            "Oncology": 1402019,
            "Pulmonary hypertension": 1402019,
            "Discovery, product, development & supply": 1402019,
            "Data science": 1402019,
            "Med tech": 1402019,
            "Vision": 1402019,
            "Self care": 1402019,
            "Skin health": 1402019 
        },
        activePerid: {
            "< 1": 57,
            "1-5": 21,
            "5-10": 150,
            ">10": 75
        },
        fundingByState: {
            "Maryland": {
                "private": 1000000000,
                "government": 1000000000
            },
            "North Carolina": {
                "private": 1000000000,
                "government": 1000000000
            },
            "Virginia": {
                "private": 1000000000,
                "government": 1000000000
            },
            "South Carolina": {
                "private": 1000000000,
                "government": 1000000000
            },
            "Delaware": {
                "private": 1000000000,
                "government": 1000000000
            },
            "District of Columbia": {
                "private": 1000000000,
                "government": 1000000000
            }
        },
        companyByState: {
            "South Carolina": 16000,
            "North Carolina": 9000,
            "Virginia": 25000,
            "Washington DC": 12000,
            "Maryland": 21000,
            "Delaware": 56000
        },
        mapData: {}
    },
};

export const headerAnalytics = createSlice({
    name: 'header/Analytics',
    initialState: defaultState,
    reducers: {
        updateAnalytics: (state, action) => {
            state.headerAnalytics[action.payload.key] = action.payload.value;
        },

    }
})

export const analyticsAction = headerAnalytics.actions;
export default headerAnalytics.reducer;