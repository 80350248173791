import React from "react";
import { RememberMeWrapper } from "../pages/Auth/style.Auth"

const RememberMe = ({ value, handleChange }) => {
    const label="Remember me";

    return (
        <RememberMeWrapper>
            <input 
                id={label} 
                type="checkbox" 
                checked={value} 
                onChange={() => {handleChange(label)}} 
                style={{ margin: "8px 8px" }}
            />
            {label}
        </RememberMeWrapper>
        );
};

export default RememberMe;