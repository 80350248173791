import React, { useState, useEffect, useRef } from "react";

import { DropdownWrapper, 
    DropdownLabel, 
    DropdownIcon,
    DropdownInput, 
    OptionsMenu, 
    OptionItem } from "./style.dropdown";

export default function Dropdown(props) {
    
    const [ dropdownState, setDropdownState ] = useState(false);
    const { value, handleDropdownValueChange } = props;
    const dropdownRef = useRef(null);

    const changeDropdownState = () => {
        const prevState = dropdownState;
        setDropdownState(!prevState);
    };

    const handleOptionClick = (option)=> {
        setDropdownState(false);
        handleDropdownValueChange(option);
    }

    const close = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownState(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', close, true);
    
        return () => {
            window.removeEventListener('click', close, true);
        };
    }, []);


    const getDropdownOptions = (name, options) => {
        return Array.from(options).map((entry) => {
            const [ option, selectable ] = entry;
            const ifSelectable = Boolean(selectable);
            return (
                <OptionItem
                    key={option}
                    onClick={
                        ifSelectable ?
                            () => handleOptionClick(option)
                            : undefined
                    }
                    ifSelectable={ifSelectable}
                >
                    <option value={option}>
                        {option}
                    </option>
                </OptionItem>
            )
        });
    };

    const ifOpen = dropdownState;

    return (
        <DropdownWrapper ifOpen={ifOpen} ref={dropdownRef}>
            <DropdownLabel onClick={changeDropdownState}>
                <DropdownInput
                    name={props.name}
                    placeholder={props.placeholder}
                    type="text"
                    value={value}
                    autocomplete={false}
                    readOnly={true}
                    onVl
                />
                <DropdownIcon ifOpen={ifOpen}/>
            </DropdownLabel>
            <OptionsMenu>
                {
                    getDropdownOptions(props.name, props.options)
                }
            </OptionsMenu>
        </DropdownWrapper>
    );

}