import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { PORT, PATH, MODE } from "services/api";
import { useAuth } from "contexts/AuthContext";

import { 
    HomePage,
    GreetingsArea,
    GreetingsHeading,
    GreetingsSubHeading,
    InfoArea, 
    InfoInput, 
    SolidButton, 
    InfoTitleContainer,
    InfoTitleHeading, 
    InfoTitleSubHeading,
    LinkTextButton,
    TitleContainer, 
    JlabsLogo,  
    WiserLogo,
    InputContainer,
    LoginLogo} from './style.Auth';

import { Footer, Password, Error } from "components";
import { validateField } from "./utils/Validator";

import { useDispatch } from 'react-redux';
import { verifyTempPassword } from 'store/Requests/auth'; 

import ProductTitle from "assets/image/logo.png";
import ProductLogo from "assets/image/wiser-logo.png";

import { Loader } from "components";

export default function SignUpForm(props){

    const { userSignIn } = useAuth();
    const navigate = useNavigate();
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const verifyDetailsButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const { values, handleChange } = props;
    
    const [ loading, setLoading ] = useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [ focusedFields, setFocusedFields ] = React.useState({
        email: false,
        password: false
    });

    const [errorMessages, setErrorMessages] = React.useState({ 
        "areValid": true,
        "errors": {
            "email": { 
                isValid: true, 
                error: null
            },
            "password": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleFieldsFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
    }

    const handleIconClick = () => {
        return window.open("https://www.globalactionalliance.net/", "_blank")
    }

    const handleLinkClick = () => {
        return window.open("", "_blank")
    }

    const validateInputFields = (emailRef, pwdRef) => {
        
        let output = { areValid: true, errors: ""}

        const emailValidationResult = validateField("email", [emailRef]);
        const pwdValidationResult = validateField("password", [pwdRef]);

        if( emailValidationResult.isValid && pwdValidationResult.isValid ){
            output = { 
                areValid: true, 
                errors: { 
                    "email": emailValidationResult, 
                    "password": pwdValidationResult 
                }}
        }else{
            output = { 
                areValid: false, 
                errors: { 
                    "email": emailValidationResult, 
                    "password": pwdValidationResult 
                }}
        }

        return output;
    }

    
    useEffect(()=>{

        if (didMountRef.current){

            const areInputFieldsValid = validateInputFields(emailInputRef, passwordInputRef);
            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: areInputFieldsValid.areValid,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: areInputFieldsValid.errors.email.isValid, 
                            error: areInputFieldsValid.errors.email.error},
                        password: { 
                            ...errorMessages.errors.password, 
                            isValid: areInputFieldsValid.errors.password.isValid, 
                            error: areInputFieldsValid.errors.password.error},
                    }
                });

            }else{
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: true, 
                            error: null},
                        password: { 
                            ...errorMessages.errors.password, 
                            isValid: true, 
                            error: null},
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            }
        }else{
            didMountRef.current = true;
            verifyDetailsButtonRef.current.disabled = true;
        }
    },[values]);

    const verifyDetails = e => {

        if(isSubmitting == false)
            return;
        
        setLoading(true);
        dispatch(
            verifyTempPassword({
                "email": values.email,
                "tempPassword": values.tempPassword
            })
        )
        .unwrap()
        .then(resJson => {
            handleChange( "isVerified", true);
            setLoading(false);
            userSignIn(resJson.token, false);
            navigate("/");

            // TODO: Update to original Two-step process after temp password back is done
            // props.nextStep();
        })
        .catch(error => {
            console.log(error);
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        });

        setLoading(false);
    }


    return (
        <HomePage>
            <Loader isVisible={loading} />
            <GreetingsArea>
                <TitleContainer onClick={handleIconClick}>
                    <JlabsLogo id="org-logo" src={ProductTitle} alt="GAA" />
                </TitleContainer>
                <GreetingsHeading>
                    Welcome to
                    <br/>
                    your dashboard
                </GreetingsHeading>
                <GreetingsSubHeading>
                    An AI life science platform that aggregates, analyzes & visualizes data to provide limitless dynamic insight from any functional perspective and level of detail
                </GreetingsSubHeading>
            </GreetingsArea>

            <InfoArea>
                <InfoTitleContainer>
                    <LoginLogo>
                        <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" />
                        <InfoTitleHeading>{"Sign up"}</InfoTitleHeading>
                    </LoginLogo>
                    
                </InfoTitleContainer>
                <InfoTitleSubHeading>
                    {"Please contact your company representative if you do not have a temporary password. If you do not know your representative, "}
                    <LinkTextButton
                        onClick={handleLinkClick}
                        >
                            click here
                        </LinkTextButton>
                </InfoTitleSubHeading>
                <InputContainer>
                    <InfoInput
                        type={"email"}
                        value={values.email}
                        ref={emailInputRef}
                        required={true}
                        className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("email", e.target.value)}}
                        onFocusCallback={(e) => {handleFieldsFocusChange("email", true)}}
                        onBlurCallback={(e) => {handleFieldsFocusChange("email", false)}}
                        placeholder="Company email ID"
                        keyDownCallback={verifyDetails}
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email)}
                        message={ errorMessages.errors.email.error }
                    />
                    
                    <Password
                        value={values.tempPassword}
                        placeholder="Temporary password"
                        required={true}
                        innerRef={ passwordInputRef }
                        className={ errorMessages.errors.password.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("tempPassword", e.target.value)}}
                        onFocusCallback={(e) => {handleFieldsFocusChange("password", true)}}
                        onBlurCallback={(e) => {handleFieldsFocusChange("password", false)}}
                        keyDownCallback={verifyDetails}
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.password.isValid || focusedFields.password)}
                        message={ errorMessages.errors.password.error }
                    />

                </InputContainer>
                <Error 
                    isVisible={ !errorMessages.errors.others.isValid }
                    message={ errorMessages.errors.others.error }
                    />
                
                <SolidButton
                    type="button"
                    ref={verifyDetailsButtonRef}
                    onClick={verifyDetails} 
                    style={{marginTop: "1vh"}}
                    disabled={ isSubmitting ? false : true }
                >
                    Create
                </SolidButton>
                <LinkTextButton 
                    onClick={() => navigate('/login')}
                    style={{marginTop: "1.5vh"}}
                >
                    Have an account? Sign in instead
                </LinkTextButton>
            </InfoArea>
            <Footer />
        </HomePage>
    )

}