import { createSlice } from '@reduxjs/toolkit';
import { verifyTempPassword } from '../Requests/auth';

const signupSlice = createSlice({
    name: "signup",
    initialState: {
        response: [],
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(verifyTempPassword.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(verifyTempPassword.fulfilled, (state, action) => {
            state.isLoading = false;
            state.response = action.payload;
        });
        builder.addCase(verifyTempPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    }
});


export const authSignUpReducer = signupSlice.reducer;