import React, { Component } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Header, Footer, Breadcrumbs, Tabs, WorldMap, Loader, ComponentTablePagination, US_Map, InfoBubble, CardDownloadOption } from "components";
import { ContentContainer, BreadcrumbContainer, ShareData, TitleRowContainer, TopicTitle, GridWrapper, SummaryTitle, SummaryTitlePagecount,WorldMapContainer, ShadePlate, NoData, ModalButton, SummaryTitleWrapper, CardOptionsWrapper } from "pages/style";
import { AboutContentBottomBlur, AboutContent, About, Contacts, ManufactureFunding, NewsContainer, TotalFundingAmount, HomeGrid, ManufactureLicensed, ManufactureMaps, ManufactureNews, HomeDetailContainer, ManufactureTrials, ManufactureViewMore, TableContainer, TableBody, TableHead, TableDataDiv, TableBodyByLicensed, TableHeadLicensed, NewsBlock, NewsInfo, NewsButton, TableContainerLicensed, TotalGovernmentFunding, TotalPrivateFunding, TableContainerCompanyInfo, TotalFundingAmountHomeD, FocueAreacContainer, FocueArea, JlabsStatus, TableContainer2 } from './style.homeDetails';

import { processNumberString } from "utils/Function/number";

import { connect } from "react-redux";
import { PORT, PATH, MODE } from "services/api";
import { getOrganisationDetails } from "store/Requests/homeDetails";

import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import { getEncryptObj } from "utils/encrypt";
import { AuthContext } from '../../../contexts/AuthContext';
import withRouter from "utils/ComponentWrapper";


const EMPTY_DATA = "No data associated with this organization";

const colorLabel = [
    "#5C2583",
    "#5C2583",
    "#584A9F",
    "#584A9F",
    "#796CB1",
    "#796CB1",
    "#DED3E6",
    "#DED3E6",
    "#F0F0F0",
];

class HomeDetails extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            manufacturer: "",
            loading: false,

            AboutContent: "",
            mapData: "",
            clinicalTrialsCount: 0,
            clinicalTrials: [],
            vaccineAvailabilityCount: 0,
            fundingCount: 0,
            fundingAmount: 0,
            vaccineAvailability: [],
            contacts: [],
            annualReport: "",
            linkedInLink: "",
            pressRelease: "",
            websiteLink: "",

            info: {
                "founder": "Jean-René Sautier Jean-François Dehecq",
                "year": "2004",
                "status": "current"
            },
            funding: {
                "government": 0,
                "private": 0
            },
            focusAreas: [],

            currentPage: 0,
            tableContent: 2,
            governmentFunding: 1,
            currentPageContacts: 0,
            tableContentContacts: 3,

            focusAreasPage: 1,
            totalFocusAreaToDisp: 8
        };

        this.handleTablePage = this.handleTablePage.bind(this);
        this.handleHandleFundingClick = this.handleHandleFundingClick.bind(this);
        this.handleFocusPages = this.handleFocusPages.bind(this);
    };

    queryFilteredData = (id) => {
        this.setState(
            () => ({
                loading: true,
            }), async () => {

            try {
                const dataString = JSON.stringify({
                    orgName: id
                });
                const encryptObj = await getEncryptObj(dataString);
                
                this.props.getOrganisationDetails({ encryptObj: encryptObj, currentUser: this.context.currentUser })
                .unwrap()
                .then(rawResponse => {
                    if(rawResponse.ok)
                        return rawResponse.json();
                    
                    if (!rawResponse.ok && rawResponse.status === 403) {
                        this.context.userLogOut();
                    }
                    
                }).then(results => {
                    this.setState({
                        loading: false,
                        mapData: results.mapData,
                        AboutContent: results.about,
                        clinicalTrialsCount: results.clinicalTrialsCount,
                        clinicalTrials: results.clinicalTrials,
                        vaccineAvailabilityCount: results.vaccinesCount,
                        vaccineAvailability: results.vaccines,
                        fundingAmount: results.funding.private,
                        fundingCount: results.count,
                        funding: results.funding,
                        annualReport: results.annual_report,
                        websiteLink: results.website_link,
                        pressRelease: results.press_releases,
                        linkedInLink: results.linkedin_link,
                        manufacturer: `${id}`,
                        contacts: results.contacts,
                        currentPage: parseInt(`${results.clinicalTrials.length > 0 ? 1 : 0}`),
                        currentPageContacts: parseInt(`${results.contacts.length > 0 ? 1 : 0}`),
                        info: results.info,
                        focusAreas: results.focusAreas
                    });
                })
                .catch(error => {
                    console.error(`Error Occurred during fetch: ${error}`);
                })
                
            } catch (err) {
                console.error(`Error Occurred before fetch: ${err.message}`);
            }
        });
    };


    componentDidMount() {
        const { id } = this.props.params;
        this.queryFilteredData(id);
    };

    handleTablePage = (pageNumber) => {
        this.setState((prevState) => ({ currentPage: pageNumber }));
    };

    handleHandleFundingClick = (pageNumber) => {
        this.setState((prevState) => ({ governmentFunding: pageNumber }));
    };

    handleTablePageContacts = (pageNumber) => {
        this.setState((prevState) => ({ currentPageContacts: pageNumber }));
    };

    handleFocusPages = (pageNumber) => {
        this.setState((prevState) => ({ focusAreasPage: pageNumber }));
    };

    WorldMapColorMount = (e) => {
        let percentValue = null;

        if (e.countryValue !== null) {
            percentValue = (e.countryValue / e.maxValue) * 100;
        }

        if (percentValue > 90) {
            return colorLabel[0];
        } else if (percentValue <= 90 && percentValue > 75) {
            return colorLabel[1];
        } else if (percentValue <= 75 && percentValue > 60) {
            return colorLabel[2];
        } else if (percentValue <= 60 && percentValue > 45) {
            return colorLabel[3];
        } else if (percentValue <= 45 && percentValue > 30) {
            return colorLabel[4];
        } else if (percentValue <= 30 && percentValue > 15) {
            return colorLabel[5];
        } else if (percentValue <= 15 && percentValue >= 0) {
            return colorLabel[6];
        } else {
            return colorLabel[8];
        }
    };

    render = () => {
        const pageCountofTableContacts = Math.ceil(this.state.contacts.length / this.state.tableContentContacts);
        const contentLengthContacts = this.state.contacts.length - ((this.state.currentPageContacts * this.state.tableContentContacts) - this.state.tableContentContacts);

        const pageCountofTable = Math.ceil(this.state.clinicalTrials.length / this.state.tableContent);
        const contentLength = this.state.clinicalTrials.length - ((this.state.currentPage * this.state.tableContent) - this.state.tableContent);

        return (
            <HomeDetailContainer>
                <Loader isVisible={ this.state.loading } />
                <Header history={this.props.history} isHome={true} />
                <ContentContainer>
                    <BreadcrumbContainer>
                        <Breadcrumbs history={this.props.history}/>
                        <ShareData href="mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data">Share Additional Data</ShareData>
                    </BreadcrumbContainer>

                    <TitleRowContainer>
                        <TopicTitle>{this.state.manufacturer}</TopicTitle>
                    </TitleRowContainer>

                    <Tabs
                        stats={{
                            "Funded Programs": this.state.fundingCount,
                            "Clinical Trials": this.state.clinicalTrialsCount
                        }}
                        linkStats={[
                            { "View Annual Report": this.state.annualReport },
                            { [`Take me to ${this.state.manufacturer}.com`]: this.state.websiteLink},
                            { "Take me to LinkedIn": this.state.linkedInLink },
                            { [`Take me to Press Release`]: this.state.pressRelease },
                            // { [`${this.state.clinicalTrialsCount > 1 ? this.state.clinicalTrialsCount :''} Clinical Trials`]: this.state.clinicalTrialsCount },
                            // { [`${this.state.fundingCount > 1 ? this.state.fundingCount : ''} Funded Programs`]: this.state.fundingCount},
                        ]}
                    />
                    
                    <HomeGrid>
                        <About
                            style={{ position: 'relative', overflow: 'hidden' }}>
                            <GridWrapper>
                                <SummaryTitleWrapper>
                                    <SummaryTitle>About</SummaryTitle>
                                    <CardOptionsWrapper>
                                        {/* <CardDownloadOption
                                            onClick={()=> {}}
                                            disabled={true}
                                        /> */}
                                        <InfoBubble
                                            disclaimer={"NA"}
                                            disabled={true}
                                        />
                                    </CardOptionsWrapper>
                                </SummaryTitleWrapper>
                                {
                                    this.state.AboutContent && this.state.AboutContent.length > 0 ?
                                        <AboutContent>{this.state.AboutContent}</AboutContent>
                                        :
                                        <NoData>No Description Associated with Organization</NoData>
                                }
                                <AboutContentBottomBlur />
                            </GridWrapper>
                        </About>

                        <TotalGovernmentFunding>
                            <GridWrapper>
                                <SummaryTitle>
                                    { `Total Government Funding (contracts & grants)` }
                                </SummaryTitle>
                                    <TotalFundingAmountHomeD>
                                        { `\$ ${this.state.funding["government"].toLocaleString("en-US")}` }
                                    </TotalFundingAmountHomeD>
                            </GridWrapper>
                        </TotalGovernmentFunding>

                        <TotalPrivateFunding>
                            <GridWrapper>
                                <SummaryTitle>
                                    {this.state.governmentFunding === 1 ? `Total Government Funding (contracts & grants)`: `Total Private Funding`}
                                </SummaryTitle>
                                    <TotalFundingAmountHomeD>
                                        { `\$ ${this.state.funding["private"].toLocaleString("en-US")}` }
                                    </TotalFundingAmountHomeD>
                            </GridWrapper>
                        </TotalPrivateFunding>

                        <ManufactureLicensed>
                            <GridWrapper>
                                {/* <SummaryTitle>{`Licensed vaccines by ${this.state.manufacturer} (Page ${this.state.governmentFunding}/${pageCountofTableLicensed})`}</SummaryTitle> */}
                                <SummaryTitle>Company Information</SummaryTitle>
                                {
                                    this.state.vaccineAvailability?
                                        // <ComponentTablePagination
                                        //     currentPage={this.state.governmentFunding}
                                        //     numberOfPage={pageCountofTableLicensed}
                                        //     handlePageClick = {this.handleTablePageLicensed}
                                        // >
                                            <TableContainerLicensed calcHeight={ false }>
                                                <TableHeadLicensed>
                                                    <tr>
                                                        <th style={{width: "55%"}}>Founder</th>
                                                        <th>Founding Year </th>
                                                        {/* <th>JLABS Status</th> */}
                                                    </tr>
                                                </TableHeadLicensed>
                                                <TableBodyByLicensed>
                                                    { this.getLicensedByManufacture() }
                                                </TableBodyByLicensed>
                                            </TableContainerLicensed>
                                        // </ComponentTablePagination>    
                                    : <NoData>No Information Associated with Organization</NoData>
                                }
                            </GridWrapper>
                        </ManufactureLicensed>

                        <Contacts>
                            {this.state.contacts && this.state.contacts.length > 0 ?
                                <GridWrapper>
                                    {/* <SummaryTitle>{`Contacts (Pages ${this.state.currentPageContacts}/${pageCountofTableContacts})`}</SummaryTitle> */}
                                    <SummaryTitle>
                                        Contacts
                                        <SummaryTitlePagecount>
                                            {` (Pages ${this.state.currentPageContacts}/${pageCountofTableContacts})`}
                                        </SummaryTitlePagecount>
                                    </SummaryTitle>
                                    <ComponentTablePagination
                                        currentPage={this.state.currentPageContacts}
                                        numberOfPage={pageCountofTableContacts}
                                        handlePageClick={this.handleTablePageContacts}
                                    >
                                        <TableContainerCompanyInfo
                                            calcHeight={pageCountofTableContacts !== 1 && contentLengthContacts <= 3}
                                        >
                                            <TableHead>
                                                <tr>
                                                    <th style={{ width: "35%"}}>Name</th>
                                                    <th>Position</th>
                                                </tr>
                                            </TableHead>
                                            <TableBody>
                                                { this.getContactsTable() }
                                            </TableBody>
                                        </TableContainerCompanyInfo>
                                    </ComponentTablePagination>
                                </GridWrapper>
                                :
                                <NoData>
                                    <span style={{ inlineSize: '77%' }}>
                                        No Contact Associated with Organization
                                    </span>
                                </NoData>
                            }
                        </Contacts>

                        <ManufactureMaps>
                            {
                                this.state.focusAreas && this.state.focusAreas.length > 0 ?
                                <GridWrapper>
                                    <SummaryTitle>Focus Areas</SummaryTitle>
                                        <ComponentTablePagination
                                            currentPage={this.state.focusAreasPage}
                                            numberOfPage={Math.ceil(this.state.focusAreas.length / this.state.totalFocusAreaToDisp)}
                                            handlePageClick={this.handleFocusPages}
                                        >
                                            <FocueAreacContainer>
                                                { this.getFocusArea() }
                                            </FocueAreacContainer>
                                        </ComponentTablePagination>
                                            
                                </GridWrapper>
                            : 
                                <NoData>
                                    No Focus Areas
                                </NoData>
                            } 
                        </ManufactureMaps>

                        <ManufactureFunding>
                            
                                {/* <TotalFundingAmount>{`${this.state.fundingAmount == "0" ? '$0' : '$' + processNumberString(this.state.fundingAmount)}`}</TotalFundingAmount> */}
                                {
                                    this.state.clinicalTrials && this.state.clinicalTrials.length > 0 ?
                                        <GridWrapper>
                                            <SummaryTitle>
                                                {` Clinical Trials by ${this.state.manufacturer} `}
                                                <SummaryTitlePagecount>
                                                    {`(Pages ${this.state.currentPage}/${pageCountofTable})`}
                                                </SummaryTitlePagecount>
                                            </SummaryTitle>
                                            <ComponentTablePagination
                                                currentPage={this.state.currentPage}
                                                numberOfPage={pageCountofTable}
                                                handlePageClick = {this.handleTablePage}
                                            >
                                                <TableContainer2 calcHeight={pageCountofTable !== 1 && contentLength <= 5 }>
                                                    <TableHeadLicensed>
                                                        <tr>
                                                            <th>Clinical Trial</th>
                                                            <th>Phase</th>
                                                            <th>Country</th>
                                                        </tr>
                                                    </TableHeadLicensed>
                                                    <TableBodyByLicensed>
                                                        {this.getClinicalTrialsByManufacture()}
                                                    </TableBodyByLicensed>
                                                </TableContainer2>
                                            </ComponentTablePagination>
                                        </GridWrapper>    
                                        : 
                                        <NoData >
                                            <div style={{ inlineSize: '57.4%' }}>
                                                No Clinical Trials Associated with Organization
                                            </div>
                                        </NoData>
                                }
                        </ManufactureFunding>

                        <ManufactureTrials>
                            <GridWrapper>
                                <SummaryTitle style={{ paddingBottom: "5px" }}>
                                    {/* {`Clinical Trials by ${this.state.manufacturer} (Pages ${this.state.currentPage}/${pageCountofTable})`} */}
                                    Company Location
                                </SummaryTitle>
                                {/* <div style={{width: "100%", overflow: "hidden",}}> */}
                                    <US_Map />
                                {/* </div> */}
                                
                                {/* {
                                    this.state.clinicalTrials && this.state.clinicalTrials.length > 0 ?
                                        <ComponentTablePagination
                                            currentPage={this.state.currentPage}
                                            numberOfPage={pageCountofTable}
                                            handlePageClick = {this.handleTablePage}
                                        >
                                            <TableContainer calcHeight={pageCountofTable !== 1 && contentLength <= 5 }>
                                                <TableHeadLicensed>
                                                    <tr>
                                                        <th>Clinical Trial</th>
                                                        <th>Phase</th>
                                                        <th>Country</th>
                                                    </tr>
                                                </TableHeadLicensed>
                                                <TableBodyByLicensed>
                                                    {this.getClinicalTrialsByManufacture()}
                                                </TableBodyByLicensed>
                                            </TableContainer>
                                        </ComponentTablePagination>    
                                        : <NoData>{ EMPTY_DATA }</NoData>
                                } */}
                            </GridWrapper>
                        </ManufactureTrials>

                        {/* <ManufactureViewMore
                            disabled
                        >
                            View more
                        </ManufactureViewMore> */}
                    </HomeGrid>
                </ContentContainer>

                {/* <Footer relative /> */}
            </HomeDetailContainer>
        );
    }

    getShadePlate = () => {
        return colorLabel.map((value, index) => {
            return (
                <span
                    key={index}
                    style={{
                        backgroundColor: `${value}`,
                        width: "1.3vw",
                        height: "1.5vh",
                    }}
                />
            );
        });
    };

    getClinicalTrialsByManufacture = () => {
        const endingIndex = this.state.currentPage * this.state.tableContent;
        const startingIndex = endingIndex - this.state.tableContent;

        return this.state.clinicalTrials.slice(startingIndex,endingIndex).map((item, index) => {
            return (
                <tr key={index} style={{ fontSize: '10px' }}>
                    <td><TableDataDiv>{item.title}</TableDataDiv></td>
                    <td><TableDataDiv>{item.phase}</TableDataDiv></td>
                    <td><TableDataDiv>USA</TableDataDiv></td>
                </tr>
            );
        });
    };

    getLicensedByManufacture = () => {
        // const endingIndex = this.state.governmentFunding * this.state.tableContentLicensed;
        // const startingIndex = endingIndex - this.state.tableContentLicensed;
        return (
                <tr>
                    <td ><TableDataDiv>{this.state.info["founder"]}</TableDataDiv></td>
                    <td ><TableDataDiv>{this.state.info["year"]}</TableDataDiv></td>
                    {/* <td ><JlabsStatus>{this.state.info["status"]}</JlabsStatus></td> */}
                </tr>
            );
    };

    getContactsTable = () => {
        const endingIndex = this.state.currentPageContacts * this.state.tableContentContacts;
        const startingIndex = endingIndex - this.state.tableContentContacts;

        return this.state.contacts.slice(startingIndex, endingIndex).map((item, index) => {
            return (
                <tr key={index}>
                    <td><TableDataDiv><a href={`${item.link}`} target="_blank" style={{textDecoration: 'none', color:"#5C2583"}}>{item.name}</a></TableDataDiv></td>
                    <td><TableDataDiv>{item.position}</TableDataDiv></td>
                </tr>
            );
        });
    }

    getFocusArea = () => {
        const endingIndex = this.state.focusAreasPage * this.state.totalFocusAreaToDisp;
        const startingIndex = endingIndex - this.state.totalFocusAreaToDisp;

        return (
            this.state.focusAreas.slice(startingIndex, endingIndex).map((item, index) => {
                return (<FocueArea key={index}>{item} </FocueArea>);
            })
        );
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganisationDetails: (obj) => dispatch(getOrganisationDetails(obj)),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(HomeDetails));