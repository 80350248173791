const validateTextField = (ref) => {

    let output = { isValid: true, error: ""}

    const text = ref.current.props.value;

    if ( ref.current.props.required == true) {
        if( text.length == 0 ){
            output = { 
                isValid: false, 
                error: "This field cannot be empty."
            }
        }
        if( text.length < 3 ){
            output = { 
                isValid: false, 
                error: "This field must contain atleast 3 characters."
            }
        }
    }

    return output;
}

const validateEmailField = (ref) => {

    let output = { isValid: true, error: ""};

    const email = ref.current.props.value;

    if ( ref.current.props.required ) {
        if( email.length == 0 ){
            output = { 
                isValid: false, 
                error: "Email field cannot be empty."
            }
            return output;
        }
    }
    
    if( email.match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$") == null ){
        output = { 
            isValid: false, 
            error: "Please put in a valid email address"
        }
    }

    return output;
}

const validatePasswordField = (ref) => {
    let output = { isValid: true, error: ""}

    const pwd = ref.current.props.value;

    if ( ref.current.props.required ){
        if( pwd.length == 0 ){
            output = { 
                isValid: false, 
                error: "Password field cannot be empty."
            }
            return output;
        }
    }
    // Disabled as current test password is smaller than 10 characters.
    // if( pwd.length < 10 ){
    //     output = { 
    //         isValid: false, 
    //         error: "Passwords must contain atleast 10 digits."
    //     }
    // }

    return output;
}

const validateConfirmPasswordField = (ref1, ref2) => {
    let output = { isValid: true, error: ""}

    const pwd = ref1.current.props.value;
    const cpwd = ref2.current.props.value;

    if ( pwd.length == 0 || cpwd.length == 0) {
        output = { 
            isValid: false, 
            error: "A Password field cannot be empty."
        }
    }else if( pwd.length < 10 || cpwd.length < 10 ){
        output = { 
            isValid: false, 
            error: "Passwords must contain atleast 10 digits."
        }
    }else if ( cpwd != pwd ){
        output = { 
            isValid: false, 
            error: "Passwords do not match."
        }
    }

    return output;
}

export const validateField = ( type, events) => {
    switch( type ){
        case "text": return validateTextField(events[0]);
        case "email": return validateEmailField(events[0]);
        case "password": return validatePasswordField(events[0]);
        case "confirmPassword": return validateConfirmPasswordField(events[0], events[1]);
    }
}