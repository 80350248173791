import { createAsyncThunk } from '@reduxjs/toolkit';
import { PORT, PATH, MODE } from "services/api";

const getOrganisationDetails = createAsyncThunk('organisation', async ({encryptObj, currentUser}, { rejectWithValue }) => {

    const response = await fetch (
        `${MODE}://${PATH}:${PORT}/v1/detail?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-ByType": "application/json",
                "Authorization": `${currentUser}`
            }
        }).then(rawResponse => {
            if (!rawResponse.ok && rawResponse.status !== 403)
                return rejectWithValue(JSON.stringify({
                    status: rawResponse.status,
                    statusText: rawResponse.statusText
                }));
            return rawResponse;
        })

    return response;
});

export { getOrganisationDetails };