import { createSlice } from '@reduxjs/toolkit';
import { getHomeData } from 'store/Requests/home'; 

const requestReducer = createSlice({
    name: "request/home",
    initialState: {
        data: [],
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(getHomeData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getHomeData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getHomeData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    }
});


export const homeRequestReducer = requestReducer.reducer;