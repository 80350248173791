import styled from "styled-components";
import { homeInputStyle } from 'utils/Static/index';
import { TextButton } from 'utils/Static/static.css';

import DownUnselectedIcon from "assets/image/down-unselected.png";
import DownSelectedIcon from "assets/image/down-selected.png";
import UpUnselectedIcon from "assets/image/up-unselected.png";
import UpSelectedIcon from "assets/image/up-selected.png";


export const FiltersContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-start;
    left: 4.26vw;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
`

export const FilterWrapper = styled.div`
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    background: transparent;
    border-radius: 24px;

    max-height: ${props => props.ifOpen ? '34vh' : '3.5vh'};
    margin-right: 1vw;
    min-width: 4vw;
    padding: 0 30px;
    padding-bottom: ${props => props.ifOpen ? '5px' : '0px'};
    background-color: ${props => props.ifApplied ? '#796CB1' : '#FAFAF9'};
    overflow: hidden;

    transition: max-height 0.2s;

    & input {
        color: ${props => props.ifApplied ? '#FAFAF9' : '#381650'};
        font-size: ${props => props.theme.bodyText.fontSize};
    }

    &:hover {
        background-color: ${props => 
            (!props.ifApplied && !props.ifOpen) && '#EEE9F2' ||
            (props.ifApplied && !props.ifOpen) && '#5C2683'
        };
        cursor: pointer;
    }
`

export const FilterLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
`

export const FilterIcon = styled.img.attrs(({ifApplied, ifOpen}) => ({
    src: 
    ifApplied ? 
            (ifOpen ? UpSelectedIcon : DownSelectedIcon)
            : (ifOpen ? UpUnselectedIcon : DownUnselectedIcon)
    }))`
    width: 10px;
    height: 6px;
`

export const Input = styled.input`
    ${homeInputStyle}
    padding: 0;
    text-align: left;
    height: 3.5vh;

    &::placeholder {
        color: inherit
    }
`

export const OptionsMenu = styled.ul`
    list-style: none;
    padding: 10px 20px 10px 10px;
    margin: 0;
    width: 100%;
    max-width: 15vw;
    max-height: 25vh;

    border-top: 1px solid #381650;

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
        /* background-color: rgb(221, 221, 221);
        border-radius: 100vw; */
        margin-top: 0.4em;
    }
`

export const OptionItem = styled.li`
    width: 85%;
    padding: 2px 1px 2px 5px;
    cursor: ${props => props.ifSelectable ? "pointer" : "default"};
    color: ${
        props => props.ifApplied ? 
            (props => props.ifSelectable ? "#FAFAF9" : "#B7B0D3")
            : (props => props.ifSelectable ? "#381650" : "#C4C4C4")
    };
    overflow-x: hidden;

    &:hover {
        background-color: ${
            props => props.ifApplied ? 
                (props => props.ifSelectable ? "#5C2583" : "inherit")
                : (props => props.ifSelectable ? "#EEE9F2" : "inherit")
        };
        border-radius: 6px;
    }
`

export const ResetFilter = styled(TextButton)`
    line-height: 3.5vh;
    color: #381650;
    font-size: ${props=> props.theme.heading4.fontSize};

    &:hover {
        cursor: pointer;
    }
`