import React from 'react';
import { InfoBubbleWrapper } from "./style.infobubble";
import { BsInfoCircle } from "react-icons/bs";

const InfoBubble = ({ disclaimer, disabled }) => {

    return (
        <InfoBubbleWrapper
            data-tooltip={disclaimer}
            disabled={disabled}
        >
            <BsInfoCircle
                color={disabled? "#6C7073" : "#584A9F"}
            />
        </InfoBubbleWrapper>
    );

}

export default InfoBubble;