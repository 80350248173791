import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { PORT, PATH, MODE } from "services/api";

import { useAuth } from "contexts/AuthContext";

import { 
    HomePage,
    GreetingsArea,
    GreetingsHeading,
    GreetingsSubHeading,
    InfoArea, 
    InfoInput, 
    SolidButton, 
    InfoTitleContainer,
    InfoTitleHeading, 
    InfoTitleSubHeading,
    TitleContainer, 
    JlabsLogo,
    WiserLogo,
    InputContainer, 
    LoginLogo} from './style.Auth';

import { Footer, Password, Error } from "components";
import { validateField } from "./utils/Validator";

import { useDispatch } from 'react-redux';
import { registerUser } from 'store/Requests/auth';

import ProductTitle from "assets/image/logo.png";
import ProductLogo from "assets/image/wiser-logo.png";

import { Loader } from "components";

export default function CreateAccountForm(props){

    const navigate = useNavigate();
    const { userSignIn } = useAuth();
    const nameInputRef = useRef(null);
    const departmentInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const confirmPasswordInputRef = useRef(null);
    const createAccountButtonRef = useRef(null);
    const didMountRef = useRef(false);
    
    const dispatch = useDispatch();

    const { values, handleChange } = props;
    const [ loading, setLoading ] = useState(false);
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ isSubmitting, setIsSubmitting ] = React.useState(false);
    const [ focusedFields, setFocusedFields ] = React.useState({
        name: false,
        department: false,
        password: false
    });


    const [ errorMessages, setErrorMessages ] = React.useState({ 
        "areValid": true,
        "errors": {
            "name": { 
                isValid: true, 
                error: null
            },
            "department": { 
                isValid: true, 
                error: null
            },
            "confirmPassword": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleFieldsFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
    }


    const handleIconClick = () => {
        return window.open("https://www.globalactionalliance.net/", "_blank")
    }

    const validateInputFields = (nameRef, departmentRef, pwdRef, cpwdRef) => {
        
        let output = { areValid: true, errors: ""}

        const nameValidationResult = validateField("text", [nameRef]);
        const departmentValidationResult = validateField("text", [ departmentRef ]);
        const cpwdValidationResult = validateField("confirmPassword", [pwdRef, cpwdRef ]);

        if( nameValidationResult.isValid && departmentValidationResult.isValid && cpwdValidationResult.isValid ){
            output = { 
                areValid: true, 
                errors: { 
                    "name": nameValidationResult,
                    "department": departmentValidationResult, 
                    "confirmPassword": cpwdValidationResult 
                }}
        }else{
            output = { 
                areValid: false, 
                errors: { 
                    "name": nameValidationResult,
                    "department": departmentValidationResult, 
                    "confirmPassword": cpwdValidationResult 
                }}
        }

        return output;
    }

    useEffect(()=>{

        if (didMountRef.current){

            const areInputFieldsValid = validateInputFields(
                                                            nameInputRef, 
                                                            departmentInputRef, 
                                                            passwordInputRef, 
                                                            confirmPasswordInputRef
                                                            );

            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: false,
                    errors: {
                        ...errorMessages.errors,
                        name: { 
                            ...errorMessages.errors.name, 
                            isValid: areInputFieldsValid.errors.name.isValid, 
                            error: areInputFieldsValid.errors.name.error},
                        department: { 
                            ...errorMessages.errors.department, 
                            isValid: areInputFieldsValid.errors.department.isValid, 
                            error: areInputFieldsValid.errors.department.error},    
                        confirmPassword: { 
                            ...errorMessages.errors.confirmPassword, 
                            isValid: areInputFieldsValid.errors.confirmPassword.isValid, 
                            error: areInputFieldsValid.errors.confirmPassword.error},
                    }
                });

            }else{
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        name: { 
                            ...errorMessages.errors.name, 
                            isValid: true, 
                            error: null},
                        department: { 
                            ...errorMessages.errors.department, 
                            isValid: true, 
                            error: null},    
                        confirmPassword: { 
                            ...errorMessages.errors.confirmPassword, 
                            isValid: true, 
                            error: null},
                        others: {
                            ...errorMessages.errors.others, 
                            isValid: true, 
                            error: null
                        }
                    }
                });
            }

        }else{
            didMountRef.current = true;
            createAccountButtonRef.current.disabled = true;
        }

    },[values, confirmPassword]);

    const createAccount = e => {

        if(isSubmitting == false)
            return;

        setLoading(true);
        
        dispatch(
            registerUser({
                "email": values.email,
                "name": values.name,
                "department": values.department,
                "newPassword": values.newPassword
            })
        )
        .unwrap()
        .then(resJson => {
            setLoading(false);
            userSignIn(resJson.token, false);
            navigate("/");
        })
        .catch(error => {
            console.log(error)
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        });
        setLoading(false);
    }


    return (
        <HomePage>
            <Loader isVisible={loading} />
            <GreetingsArea>
                <TitleContainer onClick={handleIconClick}>
                    <JlabsLogo id="org-logo" src={ProductTitle} alt="GAA" />
                </TitleContainer>
                <GreetingsHeading>
                    Welcome to
                    <br/>
                    your dashboard
                </GreetingsHeading>
                <GreetingsSubHeading>
                    An AI life science platform that aggregates, analyzes & visualizes data to provide limitless dynamic insight from any functional perspective and level of detail
                </GreetingsSubHeading>
            </GreetingsArea>

            <InfoArea
                // style={{ padding: "3.1vh 2.3vw 3.1vh 2.8vw" }}
            >
                <InfoTitleContainer>
                    <LoginLogo>
                        <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" />
                        <InfoTitleHeading>{"Sign up"}</InfoTitleHeading>
                    </LoginLogo>
                </InfoTitleContainer>
                <InfoTitleSubHeading>
                    {"Please create your personal account and choose a new password."}
                </InfoTitleSubHeading>
                <InputContainer>
                    <InfoInput
                        type={"text"}
                        value={values.name}
                        ref={nameInputRef}
                        required={true}
                        className={ errorMessages.errors.name.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("name", e.target.value)}}
                        onFocusCallback={(e) => {handleFieldsFocusChange("name", true)}}
                        onBlurCallback={(e) => {handleFieldsFocusChange("name", false)}}
                        placeholder="Name"
                        keyDownCallback={createAccount}
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.name.isValid || focusedFields.name)}
                        message={ errorMessages.errors.name.error }
                    />
                    
                    <InfoInput
                        type={"text"}
                        value={values.department}
                        ref={departmentInputRef}
                        required={true}
                        className={ errorMessages.errors.department.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("department", e.target.value)}}
                        onFocusCallback={(e) => {handleFieldsFocusChange("department", true)}}
                        onBlurCallback={(e) => {handleFieldsFocusChange("department", false)}}
                        placeholder="Department"
                        keyDownCallback={createAccount}
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.department.isValid || focusedFields.department)}
                        message={ errorMessages.errors.department.error }
                    />

                    <Password
                        value={values.newPassword}
                        placeholder="Password"
                        required={true}
                        innerRef={ passwordInputRef }
                        className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("newPassword", e.target.value)}}
                        onFocusCallback={(e) => {handleFieldsFocusChange("password", true)}}
                        onBlurCallback={(e) => {handleFieldsFocusChange("password", false)}}
                        keyDownCallback={createAccount}
                    />
                    <Password
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        required={true}
                        innerRef={ confirmPasswordInputRef }
                        className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {setConfirmPassword(e.target.value)}}
                        onFocusCallback={(e) => {handleFieldsFocusChange("password", true)}}
                        onBlurCallback={(e) => {handleFieldsFocusChange("password", false)}}
                        keyDownCallback={createAccount} 
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.confirmPassword.isValid || focusedFields.password)}
                        message={ errorMessages.errors.confirmPassword.error }
                    />

                </InputContainer>
                <Error
                    isVisible={ !errorMessages.errors.others.isValid }
                    message={ errorMessages.errors.others.error }
                    />
                
                <SolidButton
                    type="button"
                    ref={createAccountButtonRef}
                    onClick={createAccount} 
                    disabled={ errorMessages.areValid ? false : true }
                    style={{ marginTop: '1vh', marginBottom: "2.7vh"}}
                >
                        Create account
                </SolidButton>
                
            </InfoArea>
            <Footer />
        </HomePage>
    )
}