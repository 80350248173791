import { PopupBackdropContainer } from "./style.popup";

const PopupBackdrop = ({isDisplay, zIndex}) => {
    return (
        <PopupBackdropContainer
            isDisplay={isDisplay}
            zIndex={zIndex}
        />
    );
};

export default PopupBackdrop;