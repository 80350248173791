import { MODE, PATH, PORT  } from "services/api";

export const getEncryptObj = async (data) => {
    const encryptRes = await fetch(
        `${MODE}://${PATH}:${PORT}/v1/encrypt`,
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
        }
    );
    const encryptObj = await encryptRes.json();
    return encryptObj;
};
