import React, { useState } from 'react';

import SignUpForm from './SignUpForm';
import CreateAccountForm from './CreateAccountForm';


export default function SignupController() {

    const [ step, setStep ] = useState(1);
    const [ values, setValues ] = useState({
        email: '',
        tempPassword: '',
        name: '',
        department: '',
        newPassword: '',
        isVerified: ''
    });

      // Proceed to next step
    const nextStep = () => {
        setStep(prevStep => prevStep + 1);
    }

    // Go back to prev step
    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    }

    const handleFieldUpdate = (input, newValue) => {
        setValues(prevState => {
            return {...prevState, [input]: newValue}
        });
    }

    switch (step) {
        case 1:
            return (
                <SignUpForm
                    nextStep={nextStep}
                    handleChange={handleFieldUpdate}
                    values={values}
                />
            );
        case 2:
            return (
                <CreateAccountForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleFieldUpdate}
                    values={values}
                />
            );
        default:
            return (
                <p>Default page for signup controller</p>
            )
    }
}
