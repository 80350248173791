import React, { useContext, useEffect, useState } from "react";

export const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const [ currentUser, setCurrentUser ] = useState();
    const [loading, setLoading] = useState(true);


    const userSignIn = (userInfo, rememberToken) => {
        localStorage.setItem("token", JSON.stringify(userInfo));
        setCurrentUser(userInfo);
    }

    const userLogOut = () => {
        if( localStorage.getItem("token") )
            localStorage.removeItem("token");
        setCurrentUser();
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token') || null);
        setCurrentUser(token);
        setLoading(false);
    }, [])

    return (
        <AuthContext.Provider value={{currentUser, userSignIn, userLogOut}}>
            {/* Don't render children until loading is done */}
            {!loading && children}
        </AuthContext.Provider>
    )
}