import { createSlice } from '@reduxjs/toolkit';


export const defaultState = {
    filters: {
        sort: "Size",
        state: "",
        city: "",
        focusAreas: "",
        size: "",
        // type: "",
        // Technology: "",
        // Organisation_Type: "",
        // Organisation_Size: "",
        company_received_funding: "",
        status: "",
    },
};

const filters = createSlice ({
    name: 'filters/home',
    initialState: defaultState,
    reducers: {

        changeFilter: (state, action) => {
            state.filters[action.payload.key] = action.payload.value;
        },

        deselectFilter: (state, action) => {
            state.filters[action.payload.key] = "";
        },

        updateFilter: (state, action) => {
            state = action.payload.filters;
        }, 

        clearFilter: (state, action) => {
            state.filters = defaultState.filters;
        }

    }
})

export const filterActions = filters.actions;
export default filters.reducer;