import styled, { keyframes } from "styled-components";
import backgroundImage from 'assets/image/header_background.png';
import { media } from 'utils/Static/index';



export const HeaderComponent = styled.div`
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center center;
    position: relative;
    /* position: -webkit-sticky; */
    top: 0;
    height: ${props => props.isScrolled ? `7vh` : `70vh`};
    width: 100%;
    z-index: ${props => props.isScrolled ? `100` : `0`};
    box-sizing: border-box;
    
    display: flex;
    /* flex-direction: column; */
    /* gap: 25%; */
    justify-content: space-between;
    align-items: center;
    
    align-self: flex-start;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    padding: 0 0.8vw;
    margin-bottom: 0.8vh;

    ${media.monitor} {
        min-height: 59px;
    }

    ${media.large_desktop} {
        min-height: 51px;
    }

    ${media.laptop} {
        min-height: 40px;
    }

    /* transition: all 0.5s; */
`;


export const LogoComponent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    #org-logo {
        height: 65px;
    }

    ${media.monitor} {
        #org-logo {
            height: 45px;
        }
    }

    ${media.large_desktop} {
        #org-logo {
            height: 34px;
        }
    }

    ${media.laptop} {
        #org-logo {
            height: 26.7px;
        }
    }
`;

export const TitleImage = styled.img`
    height: 30px;
    opacity: ${props => props.isScroll ? '1' : `0`};

    /* transition: all 2s; */

    ${media.monitor} {
        height: 28px;
    }

    ${media.large_desktop} {
        height: 15px;
    }

    ${media.laptop} {
        height: 15px;
    }
`;


export const TitleImage2 = styled.img`
    height: 3.5vw;
    padding: 2vh;
`

export const DownSelectArrow = styled.img`
    height: 0.6vw;
    padding: 1vh;
`

export const UserName = styled.div`
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    font-size: ${props => props.theme.heading4.fontSize};
    /* line-height: 20px; */
    /* text-align: center; */

    width: 10vw;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.2vw;
    color: #F0F0F0;

    cursor: pointer;

    span{
        height: 100%;
        display: flex;
        align-items: center;
    }

    position: relative;

    &::after, &::before {
        --scale: 0;
        --tooltip-color: #FFFFFF;
        --arrow-size: 7px;

        position: absolute;
        left:50%;
        bottom: -.15rem;
        outline: none;
        /* z-index: 1; */

        max-height: 17vh;
        overflow-y: auto;

        transform: translateX(-34%) translateY(var(--translate-y,0)) scale(var(--scale));
        transition-delay: 300ms;
        transition-property: 500ms transform;

    }
    
    &::before{
        --translate-y: calc(100% + var(--arrow-size));
        
        content: attr(data-tooltip);
        color:  #381650;
        padding: 1vh 2vw;
        width: max-content;
        max-width: 14vw;
        height: auto;
        background: var(--tooltip-color);
        
        font-size: ${props=> props.theme.bodyText.fontSize};
        font-weight: ${props=> props.theme.bodyText.fontWeight};
        border-radius: 20px;

        transform-origin: top center;
    }

    &:hover::before,
    &:hover::after {
        --scale: 1;
        z-index: 1;
    }

    /* &::after {
        --translate-y: var(--arrow-size);

        content: '';
        border: 6px solid transparent;
        border-bottom-color: var(--tooltip-color);
        transform-origin: bottom center;
    } */

`

export const SearchBar = styled.div`
    width: 48vw;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    padding: 0px 2px 0px 2vw;
    box-shadow: 3px 12px 20px 0px #589EC31A;

    background-color: #FAFAF9;

    & > input {
        height: 3.6vh;
        width: 65vw;
        background: transparent;
        border: none;
        font-size: ${props=> props.theme.heading4.fontSize};
        font-weight: ${props=> props.theme.heading4.fontWeight};
    }

    & > input:focus {
        outline: none;
    }
`


export const SubmitBtn = styled.div`
    box-sizing: border-box;
    background-color: #4DBBF1;
    padding: 15px 10px;
    width: 12vw;
    max-height: 3.5vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 40px;
    font-size: ${props=> props.theme.heading3.fontSize};
    font-weight: ${props=> props.theme.heading3.fontWeight};

    cursor: pointer;

    ${media.large_desktop} {
        padding: 5px 10px;
    }

    ${media.laptop} {
        padding: 3px 10px;
    }
`


export const HeaderTitleContainer = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    justify-content: space-between;

    padding: 1vh;
`

export const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    gap: .6vw;

    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.7rem;

    color: #FFFFFF;

    #org-logo{
        height: 4.5vh;
    }

`

export const BackArrow = styled.div`
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: ${props => props.theme.heading5.fontWeight};
    font-size: ${props => props.theme.heading5.fontSize};
    /* line-height: 20px; */
    /* text-align: center; */

    width: 6vw;

    display: flex;
    align-items: center;
    gap: 0.5vw;
    color: #F0F0F0;

    span{
        height: 100%;
        display: flex;
        align-items: center;
    }

    span:first-child{
        font-size: 16px;
    }

    :hover{
        cursor: pointer;
    }
`

export const SearchBarWapper = styled.div`
    display: flex;
    flex-direction: ${props => props.isScroll ? `row` : `column`};
    align-items: center;
    gap: ${props => props.isScroll && `8vw`};
    justify-content: ${props => !props.isScroll && `center`};

    flex-grow: ${props => !props.isScroll && 1};
    width: 100%;
    margin-bottom: ${props => !props.isScroll && "10vh"};

    /* transition: all 1s; */
`

export const ScrollLogoContainer = styled.div`
    width: 100%;
    height: calc(50px + 4vh);
    padding-bottom: 2vh;

    position: relative;


    /* display: flex;
    align-items: center; */
`

const ScrollLogoAnimation = keyframes`
    0%{
        bottom: 2vh;
    }
    30%{
        bottom: 5vh;
    }
    60%{
        bottom: 2vh;
    }
    80%{
        bottom: 3vh;
    }
    100%{
        bottom: 2vh;
    }
`

export const ScrollLogo = styled.div`
    position: absolute;
    right: 10px;
    bottom: 2vh;
    
    height: 50px;
    width: 50px;

    border-radius: 50%;
    background-color: #ffff;

    color: #5C2583;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 5vh;

    & :hover{
        cursor: pointer;
    }

    /* animation-name: ${props => props.animate ? ScrollLogoAnimation : `none` } ;
    animation-duration: 1.2s;
    animation-iteration-count: infinite; */
     
`

