import React, { useState, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "assets/image/wiser-logo.png";
// import logoHeader from "assets/image/TitleImage2.png";
// import Logo from "assets/image/logo.png";
import logoHeader from "assets/image/TitleImage.png";
// import logoHeader2 from "assets/image/TitleImage2.png";
// import logoHeader2 from "assets/image/product_logo.png";
import { BiSearch } from "react-icons/bi";
import Parser from 'html-react-parser';
import { ComponentTablePagination, InfoBubble, Input } from "components";
import { HeaderTitle, HeaderTitleContainer, 
    SearchBar, AnalyticsWapper, 
    SubmitBtn, TitleContent, 
    TitleImage2, SwitchTabWrapper, 
    SwitchTab, AnalyticsCardWapper, 
    FocusArea, TotalCompanyCard, 
    ActiveCompanyPeriod, FundingCompanies, 
    TotalCompanies, CompanyByState, 
    FocusAreaDisUSA, CompanyPie, 
    SummaryTitleAnly, TotalFundingAmountAnly, FundingAmountWrapper,
    PieHomeWrapper, Path } from "./style.homeHeader";

import { HomeHeaderComponent, LogoComponent } from "./style.homeHeader";

import withRouter from 'utils/ComponentWrapper';

import { useDispatch, useSelector } from 'react-redux';
import { tabsActions } from 'store/Header/optionReducer';
import { GridWrapper, ModalButton, SummaryTitleWrapper, CardOptionsWrapper } from "pages/style";
import { TableBodyByLicensed, TableContainer, TableHeadLicensed, TableDataDiv } from "pages/Home/HomeDetails/style.homeDetails";

import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { MdOutlineZoomOutMap } from "react-icons/md";
import { LinkTextButton } from "pages/Auth/style.Auth";

import downSelect from "assets/image/down-selected.png";
import { DownSelectArrow, UserName } from "components/Header/style.header";
import { US_Map } from "../";
// import ReactTooltip from "react-tooltip";

import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

{
  /* Header Section: Logo, Name, Sign in options */
}
const HomeHeader = (props) => {
    const histories = props.history;
    ChartJS.register(ArcElement, Tooltip, Legend);
    const { userLogOut } = useAuth();

    // const [ collapseHeader, setCollapseHeader ] = useState(false);

    // const [totalCompany, setTotalCompany] = useState(1);
    // const [fundingCompany, setFundingCompany] = useState(1);
    const navigate = useNavigate();

    const tabs = useSelector((store) => store.headerTabs.tabs);
    const dispatch = useDispatch();

    //Add All the data for analytic section in this reducer and switching condition will automatically handle
    const analyticsData = useSelector((store) => store.headerAnalytics.headerAnalytics);

    const TABS = {
        focusArea: "Focus Areas",
        geographicalRegion: "Geographical Region",
        wiseRMatch: `WISE-R<sup>TM</sup> Match`,
        jlabsFamilyCompanies: "JLABS  Family Companies"
    };

    const [currentPage, SetCurrentPage] = useState(1);
    const tableData = Object.entries(analyticsData.companyByFocusArea);
    const tableContent = 12;
    const pageCountofTable = Math.ceil(tableData.length / tableContent);
    const contentLength = tableData.length - ((currentPage * tableContent) - tableContent);
    const handlePageClick = (val) => {
        SetCurrentPage(val);
    }



    // const [searchValue, setSearchValue] = useState(props.searchText);

    // const [isScroll, setIsScroll] = useState(props.isHome);
    // const [isAnimation, setIsAnimation] = useState(true);

    // const AnimationRef = useRef();

    // useEffect(() => {
    //     const observer = new IntersectionObserver((entries) => {
    //         if (entries[0].intersectionRatio !== 1) {
    //             setIsScroll(true);
    //         }
    //     },
    //         {
    //             threshold: 1
    //         }
    //     );  
        
    //     observer.observe(AnimationRef.current);
    // }, []);

    // const handleCompaniesClick = (pageNumber) => {
    //     setTotalCompany(pageNumber);
    // }
    // const handleFundingClick = (pageNumber) => {
    //     setFundingCompany(pageNumber);
    // }

    // const mapHandler = (event) => {
    //     alert(event.target.dataset.name);
    // };

    // const statesCustomConfig = () => {
    // return {
    //     "NJ": {
    //         fill: "navy",
    //         clickHandler: (event) => console.log('Custom handler for NJ', event.target.dataset)
    //     },
    //     "NY": {
    //         fill: "#CC0000",
    //         clickHandler: (event) => console.log('Custom handler for NJ', event.target.dataset)
    //     }
    //     };
    // };


    // const handleSearchSubmit = () => {
    //     props.submitCallback(1);
    // }

    const handleClickTabs = (value) => {
        // e.preventDefault();
        dispatch(tabsActions.selectTab({ Tab: value}));
    }

    const getCompanyFundingByState = () => {
        
        return Object.entries(analyticsData.fundingByState).map((item, index) => {
            
            const [state, {government: govFunding, private: privateFunding}] = item;

            return (
                <tr key={index} style={{ fontSize: '0.583rem' }}>
                    <td><TableDataDiv>{state}</TableDataDiv></td>
                    <td><TableDataDiv>{govFunding.toLocaleString("en-US")}</TableDataDiv></td>
                    <td><TableDataDiv>{privateFunding.toLocaleString("en-US")}</TableDataDiv></td>
                </tr>
            );
        });
    };

    const getTotalCompaniesByFocusArea = () => {
        
        // const endingIndex = this.state.currentPage * this.state.tableContent;
        // const startingIndex = endingIndex - this.state.tableContent;
        // return this.state.clinicalTrials.slice(startingIndex,endingIndex).map((item, index) => {
        const endingIndex = currentPage * tableContent;
        const startingIndex = endingIndex - tableContent;

        return tableData.slice(startingIndex, endingIndex).map((item, index) => {
            return (
                <tr key={index} style={{ fontSize: '10px' }}>
                    <td><TableDataDiv>{item[0]}</TableDataDiv></td>
                    <td><TableDataDiv>{item[1]}</TableDataDiv></td>
                </tr>
            );
        });
    };

    let data = [];
    Object.entries(analyticsData.mapData).map((entry) => {
        const state = entry[0], focusAreas = entry[1];
        data.push({
            name: state,
            values: [{val: focusAreas.slice(0, 3).join(", ")}],
            color: "#584A9F"
        })
    });


    return (
        // <div style={{position: 'relative'}}>
            <HomeHeaderComponent
                // ref={AnimationRef}
                // isScrolled = {isScroll}
            >

                {/* Search Bar */}

                { /* Expanded Header */
                    // !isScroll &&
                    <HeaderTitleContainer>
                        <div>
                            <HeaderTitle>
                                <LazyLoadImage id="org-logo" src={Logo} alt="Logo" />
                                <TitleContent
                                    // ref={AnimationRef}
                                >
                                    Global Action Alliance
                                </TitleContent>
                            </HeaderTitle>
                        </div>
                        {/* <LinkTextButton
                            //TODO: Edit this link
                            onClick={() =>  {
                                userLogOut();  
                                // navigate("/login");
                                }}
                        >
                            Log Out
                        </LinkTextButton> */}
                        <UserName
                            data-tooltip={"Sign out"}
                            onClick={() => { userLogOut() }}
                            isDisabled={false}
                        >
                            <span>User Name</span>
                            <DownSelectArrow src={downSelect} alt= "Down Select Arrow" />
                        </UserName>
                    </HeaderTitleContainer>
                }

                <AnalyticsWapper
                    // isScroll={isScroll}
                >
                    <LogoComponent>
                        {/* {isScroll && <LazyLoadImage  id="org-logo" src={Logo} alt="Logo" />}
                        {isScroll && <TitleImage isScroll={isScroll} id="org-title" src={logoHeader} alt="JLabs WISE-R" />} */}
                        
                        {/* {!isScroll && < TitleImage2 id="org-title" src={logoHeader} alt="JLabs WISE-R" />} */}

                        < TitleImage2 id="org-title" src={logoHeader} alt="JLabs WISE-R" />
                    </LogoComponent>

                <SwitchTabWrapper>
                    {
                        Object.keys(tabs).map((value, index) => {
                            return (
                                <SwitchTab
                                    key={index}
                                    isSelected={tabs[value]}
                                    onClick={() => handleClickTabs(value)}
                                    disabled={tabs[value] == 2}
                                >
                                    {Parser(TABS[value])}
                                </SwitchTab>
                            )
                        })
                    }
                </SwitchTabWrapper>

                <AnalyticsCardWapper>
                    {
                        tabs.focusArea == 1 &&
                        <>
                            <FocusArea>
                                <TotalCompanies>
                                    <GridWrapper>
                                        <SummaryTitleWrapper>
                                            <SummaryTitleAnly>Total Companies Across All Focus Areas</SummaryTitleAnly>
                                            <CardOptionsWrapper>
                                                {/* <ModalButton
                                                    //TODO: supply handler function.
                                                    onClick={()=> {}}
                                                    disabled={true}
                                                    style={{ margin: '0px 12px'}}>
                                                    <MdOutlineZoomOutMap size={12} color={"#584A9F"}/>
                                                </ModalButton> */}
                                                <InfoBubble
                                                    disclaimer={"NA"}
                                                    disabled
                                                />
                                            </CardOptionsWrapper>
                                        </SummaryTitleWrapper>
                                        <TotalFundingAmountAnly>{ analyticsData.totalCompanies.toLocaleString('en-US')}</TotalFundingAmountAnly>
                                    </GridWrapper>
                                </TotalCompanies>
                                <FundingCompanies>
                                    <GridWrapper>
                                        <SummaryTitleWrapper>
                                            <SummaryTitleAnly>Companies That Received Private Funding</SummaryTitleAnly>
                                            <CardOptionsWrapper>
                                                {/* <ModalButton
                                                    //TODO: supply handler function.
                                                    onClick={()=> {}}
                                                    disabled
                                                    style={{ margin: '0px 12px'}}
                                                >
                                                    <MdOutlineZoomOutMap size={12} color={"#584A9F"}/>
                                                </ModalButton> */}
                                                <InfoBubble
                                                    disclaimer={"NA"}
                                                    disabled={true}
                                                />
                                            </CardOptionsWrapper>
                                        </SummaryTitleWrapper>
                                        <TotalFundingAmountAnly>{analyticsData.receivedPrivateFunding.toLocaleString('en-US')}</TotalFundingAmountAnly>
                                    </GridWrapper>
                                </FundingCompanies>
                                <FundingCompanies>
                                    <GridWrapper>
                                        <SummaryTitleWrapper>
                                            <SummaryTitleAnly>Companies That Received Government Funding</SummaryTitleAnly>
                                            <CardOptionsWrapper>
                                                {/* <ModalButton
                                                    //TODO: supply handler function.
                                                    onClick={()=> {}}
                                                    disabled
                                                    style={{ margin: '0px 12px'}}
                                                >
                                                    <MdOutlineZoomOutMap size={12} color={"#584A9F"}/>
                                                </ModalButton> */}
                                                <InfoBubble
                                                    disclaimer={"NA"}
                                                    disabled={true}
                                                />
                                            </CardOptionsWrapper>
                                        </SummaryTitleWrapper>
                                        
                                        <TotalFundingAmountAnly>{analyticsData.receivedGovernmentFunding.toLocaleString('en-US')}</TotalFundingAmountAnly>
                                    </GridWrapper>
                                </FundingCompanies>
                            </FocusArea>
                            <TotalCompanyCard style={{ overflow: "hidden"}}>
                                <GridWrapper>
                                    <SummaryTitleWrapper>
                                        <SummaryTitleAnly style={{ paddingBottom: "5px" }}>Total Companies Per Focus Area (Pages{` ${currentPage}/${pageCountofTable}`})</SummaryTitleAnly>
                                        <CardOptionsWrapper>
                                            {/* <ModalButton
                                                //TODO: supply handler function.
                                                onClick={()=> {}}
                                                disabled={true}
                                                style={{ margin: '0px 12px'}}>
                                                    <MdOutlineZoomOutMap size={12} color={"#584A9F"}/>
                                            </ModalButton> */}
                                            <InfoBubble
                                                disclaimer={"NA"}
                                                disabled
                                            />
                                        </CardOptionsWrapper>
                                    </SummaryTitleWrapper>
                                    <ComponentTablePagination
                                        currentPage={currentPage}
                                        numberOfPage={pageCountofTable}
                                        handlePageClick={handlePageClick}
                                        height={'100%'}
                                    >
                                        <TableContainer calcHeight={pageCountofTable !== 1 && contentLength <= 4}>
                                            <TableHeadLicensed>
                                                <tr>
                                                    <th style={{width: "65%"}}>Focus Area</th>
                                                    <th>Total Companies</th>
                                                </tr>
                                            </TableHeadLicensed>
                                            <TableBodyByLicensed>
                                                {getTotalCompaniesByFocusArea()}
                                            </TableBodyByLicensed>
                                        </TableContainer>
                                    </ComponentTablePagination>
                                </GridWrapper>
                            </TotalCompanyCard>
                            <ActiveCompanyPeriod>
                                <GridWrapper>
                                    <SummaryTitleWrapper>
                                        <SummaryTitleAnly style={{ paddingBottom: "5px" }}>Company Active Period</SummaryTitleAnly>
                                        <CardOptionsWrapper>
                                            {/* <ModalButton
                                                //TODO: supply handler function.
                                                onClick={()=> {}}
                                                disabled={true}
                                                style={{ margin: '0px 12px'}}
                                            >
                                                <MdOutlineZoomOutMap size={12} color={"#584A9F"}/>
                                            </ModalButton> */}
                                            <InfoBubble
                                                disclaimer={"NA"}
                                                disabled
                                            />
                                        </CardOptionsWrapper>
                                    </SummaryTitleWrapper>
                                    <PieHomeWrapper>
                                        <Doughnut data={{
                                                labels: Object.keys(analyticsData.activePerid),
                                                datasets: [{
                                                    // label: "Company Active Period",
                                                    data: Object.values(analyticsData.activePerid),
                                                    backgroundColor: [
                                                        '#DED3E6',
                                                        '#796CB1',
                                                        '#584A9F',
                                                        '#5C2583'
                                                    ],
                                                    borderWidth: 3,
                                                    offset: 2,
                                                    borderRadius: 4,
                                                    responsive: true
                                                }],
                                                
                                            }}
                                            options={{
                                                maintainAspectRatio: false,
                                                animation: true,
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: true,
                                                        position: "right",
                                                        labels: { 
                                                            boxWidth: 10,
                                                            fontColor: "#381650",
                                                        }
                                                    },
                                                    tooltip: {
                                                        callbacks: {
                                                            label: (tooltipItems, data) => {
                                                                const label = tooltipItems.label;
                                                                const value = tooltipItems.raw.toLocaleString('en-US');
                                                                
                                                                return `${label}: ${value} companies`;
                                                                    
                                                            },
                                                            title: () => null
                                                        },
                                                        backgroundColor: '#ffffff',
                                                        bodyColor: '#000000',
                                                        bodyFont: {weight: '400'},
                                                        caretSize: 0
                                                    },
                                                    
                                                }, 
                                            }}
                                        />
                                    </PieHomeWrapper>
                                </GridWrapper>
                            </ActiveCompanyPeriod>
                        </>
                    }
                    {
                        tabs.geographicalRegion == 1 &&
                        // You have to edit this Area To display The data
                        <>
                            <CompanyByState>
                                <GridWrapper>
                                    <SummaryTitleWrapper>
                                        <SummaryTitleAnly style={{ paddingBottom: '5px' }}>Company Funding By State
                                        </SummaryTitleAnly>
                                        <CardOptionsWrapper>
                                            <InfoBubble
                                                disclaimer={"NA"}
                                                disabled
                                            />
                                        </CardOptionsWrapper>
                                    </SummaryTitleWrapper>
                                    <TableContainer calcHeight={false}>
                                        <TableHeadLicensed>
                                            <tr>
                                                <th style={{width: "33%"}}>State</th>
                                                <th style={{width: "33%"}}>Government Funding</th>
                                                <th>Private Funding</th>
                                            </tr>
                                        </TableHeadLicensed>
                                        <TableBodyByLicensed>
                                            {getCompanyFundingByState()}
                                        </TableBodyByLicensed>
                                    </TableContainer>
                                </GridWrapper>
                            </CompanyByState>
                            <FocusAreaDisUSA>
                                <GridWrapper>
                                    <SummaryTitleWrapper>
                                        <SummaryTitleAnly style={{ paddingBottom: "5px" }}>Focus Area Distribution By State</SummaryTitleAnly>
                                        <CardOptionsWrapper>
                                            <InfoBubble
                                                disclaimer={"NA"}
                                                disabled
                                            />
                                        </CardOptionsWrapper>
                                    </SummaryTitleWrapper>
                                    {/* <div style={{width: "100%", overflow: 'hidden',}}> */}
                                        {/* First use this simple map */}
                                        {/* <USAMap
                                            customize={statesCustomConfig()}
                                            onClick={mapHandler}
                                            width={400}
                                            height={300}
                                            tooltips={true}
                                            defaultFill={'#F0F0EF'}
                                            
                                        /> */}
                                        {/* Then Try to use this with more feature  */}
                                        <US_Map data={data} />
                                    {/* </div> */}
                                </GridWrapper>
                            </FocusAreaDisUSA>
                            <CompanyPie>
                                <GridWrapper>
                                    <SummaryTitleWrapper>
                                        <SummaryTitleAnly style={{ paddingBottom: '5px' }}>
                                            Number Of Companies In Each State
                                        </SummaryTitleAnly>
                                        <CardOptionsWrapper>
                                            <InfoBubble
                                                disclaimer={"NA"}
                                                disabled
                                            />
                                        </CardOptionsWrapper>
                                    </SummaryTitleWrapper>
                                    {/* <div> */}
                                    <PieHomeWrapper>
                                        <Doughnut data={
                                            {
                                                labels: Object.keys(analyticsData.companyByState),
                                                datasets: [{
                                                    data: Object.values(analyticsData.companyByState),
                                                    backgroundColor: [
                                                        '#5C2583',
                                                        '#584A9F',
                                                        '#735587',
                                                        '#9A6EBB',
                                                        '#BC94D7',
                                                        '#DFD2E8'
                                                    ],
                                                    borderWidth: 3,
                                                    offset: 5,
                                                    borderRadius: 4,
                                                    responsive: true
                                                }],
                                                
                                            }
                                            }
                                            options={{
                                                plugins: {
                                                    legend: {
                                                                display: true,
                                                                position: "right",
                                                                labels: { 
                                                                        boxWidth: 10,
                                                                        fontColor: "#381650",
                                                                    }
                                                            },
                                                    tooltip: {
                                                        callbacks: {
                                                            label: (tooltipItems, data) => {
                                                                const label = tooltipItems.label;
                                                                const value = tooltipItems.raw.toLocaleString('en-US');
                                                                
                                                                return `${label}: ${value} companies`;
                                                                    
                                                            },
                                                            title: () => null
                                                        },
                                                        backgroundColor: '#ffffff',
                                                        bodyColor: '#000000',
                                                        bodyFont: {weight: '400'},
                                                        caretSize: 0
                                                    },
                                                },
                                                
                                                maintainAspectRatio: false,
                                                animation:true    
                                            }}
                                            
                                        />
                                    </PieHomeWrapper>
                                    {/* </div> */}
                                </GridWrapper>
                            </CompanyPie>
                        </>
                    }
                    

                    </AnalyticsCardWapper>

                </AnalyticsWapper>

            </HomeHeaderComponent>
        // {/* </div> */}
    );
}


export default withRouter(HomeHeader);
