import styled from "styled-components";
import { media, BorderedTextButtonStyle } from 'utils/Static/index';
import { Input } from "components";
import { TextButton } from 'utils/Static/static.css';

import backgroundImage from 'assets/image/background.png';
import backgroundImage_NoText from 'assets/image/background10.png';


// --------- Shared Components ---------

export const HomePage = styled.div`
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center center;
    /* background-position: 55% 60%;
    background-repeat: no-repeat; */
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
    height: 100vh;
    color: ${props=> props.theme.colors.text.generalText};


    ${media.tablet}{
        background-size: 180%;
        background-repeat: no-repeat;
        background-position: 100% center;
    }
    
`;

export const GreetingsArea = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 1vh;
    top: 36%;
    left: 16vw;
    width: 24%;

    ${media.monitor} {
        top: 32%;
        left: 18vw;
        width: 24%;
    }

    ${media.large_desktop} {
        top: 30%;
        left: 18vw;
        width: 23%;
    }

    ${media.laptop} {
        top: 31%;
        left: 16vw;
        width: 21%;
    }

    ${media.tablet} {
        display: none;
    }
`;

export const GreetingsHeading= styled.div`
    color: ${props=> props.theme.colors.text.generalText};
    font-size: ${props=> props.theme.heading1.fontSize};
    font-weight: ${props=> props.theme.heading1.fontWeight};
`;

export const GreetingsSubHeading= styled.div`
    color: ${props => props.theme.colors.text.secondaryText};
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
`;


export const InfoArea = styled.main`
    position: relative;
    width: 25%;
    min-width: 370px;
    padding: 7.2vh 3.4vw;
    top: 17%;
    left: 64%;
    background-color: #FAFAF9E6;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    backdrop-filter: blur(25px);
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.monitor} {
        width: 24%;
        min-width: 360px;
        padding: 7vh 3.2vw;
        top: 17%;
        left: 62%;
    }

    ${media.large_desktop} {
        width: 22%;
        min-width: 340px;
        padding: 6.6vh 3vw;
        top: 16%;
        left: 59%;
    }

    ${media.laptop} {
        width: 24%;
        min-width: 300px;
        padding: 6.5vh 3vw;
        top: 15%;
        left: 56%;
    }

    ${media.tablet} {
        width: 25%;
        top: 16%;
        left: 0;
        margin: 0 auto;
    }
`;

export const InfoTitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.7vw;
`;

export const LoginLogo = styled.span`
    display: flex;
    align-items: center;
`

export const InfoTitleHeading = styled.div`
    color: ${props=> props.theme.colors.text.generalText};
    font-size: ${props=> props.theme.heading1.fontSize};
    font-weight: ${props=> props.theme.heading1.fontWeight};
`;

export const InfoTitleSubHeading = styled.div`
    margin-top: 2.7vh;
    color: ${props=> props.theme.colors.text.generalText};
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
`;


export const InputContainer = styled.div`
    margin: 1.9vh auto;
    width: 100%;
    ${'' /* width: 23vw; */}
`;

export const InfoInput = styled(Input)`
    margin: 1vh 0;
    padding: 0 2vw;
    height: 6.8vh;
    width: 100%;
    box-sizing: border-box;
    background: #F0F0EF;
    border-radius: 76.52px;
    border: none;
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
    color: ${props => props.theme.colors.text.generalText};

    &:focus {
        outline: none;
    }
    &:invalid {
        border: 1px solid #AB4646;
    }
    &:autofill {
        background: #F0F0EF;
        color: ${props=> props.theme.colors.text.generalText};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        caret-color: ${props=> props.theme.colors.black};
        -webkit-text-fill-color: ${props=> props.theme.colors.text.generalText};
        -webkit-box-shadow: 0 0 0px 30px #F0F0EF inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    &::placeholder {
        color: ${props=> props.theme.colors.text.secondaryText};
    }
    /* Disable default show-hide icon of password input in MS-Edge  */
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }
`;

export const BorderedTextButton = styled.div`
    ${BorderedTextButtonStyle}
    margin: 5px auto;
    border: ${props => props.selected ? ("2px solid " + props.theme.colors.darkestPurple): "2px solid #2F81A0"};
    color: ${props => props.selected ? props.theme.colors.text.whiteText : props.theme.colors.secondaryBlue};
    background-color: ${props => props.selected && props.theme.colors.darkestPurple};
`

export const BottomBorderedTextButton = styled.div`
    ${BorderedTextButtonStyle}
    padding: 0;
    border: 2px solid #2F81A0;
    color: ${props=> props.theme.colors.secondaryBlue}; 
    width: 45%;
    margin-top: 3vh;
    text-align: center;
    line-height: 2rem;
    border-radius: 0.8rem;
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};

    &:hover {
        cursor: pointer;
        color: ${props=> props.theme.colors.text.clickableText};
        border: 2px solid ${props=> props.theme.colors.darkestPurple};
    }
`

export const SolidButton = styled.button`
    width: 100%;
    /* disabled: true; */
    line-height: 2.5rem;
    border-width: 0px;
    background-color: #5C2583; 
    color: ${props=> props.theme.colors.text.whiteText};
    text-align: center;
    border-radius: 1rem;
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:hover {
        background-color: #5C2583;
        cursor: pointer;
    }
    &:disabled {
        color: ${props=> props.theme.colors.text.generalText};
        background-color: #DEDEDD;
        cursor: auto;
    }
`;

export const OptionsContainer = styled.div`
    width: 100%;
    color: ${props=> props.theme.colors.text.generalText};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};
    
`;

export const RememberMeWrapper = styled.label`
    display: flex;
    align-items: center;
    color: #164050;
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
`;

export const ErrorWrapper = styled.div`
    display: flex;
    margin: auto 1.5%;
    color: #AB4646;
    font-size: ${props=> props.theme.errors.large_desktop.fontSize};
    font-weight: ${props=> props.theme.errors.large_desktop.fontWeight};
`;

export const LinkTextButton = styled(TextButton)`
    color: ${props=> props.theme.colors.text.clickableText_2};
    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};

    &:hover {
        color: ${props=> props.theme.colors.text.clickableText}; 
    }

    &:disabled {
        color: ${props=> props.theme.colors.text.disabledText}; 
    }
`

export const BottomButtonRow = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 85%;
`

export const TitleContainer = styled.div`
    width: 100%;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }
`;


export const JlabsLogo = styled.img`
    height: 5.8vh;


    ${media.monitor} {
        height: 5.4vh;
    }

    ${media.large_desktop} {
        height: 5vh;
    }

    ${media.laptop} {
        height: 3.8vh;
    }

`;

export const WiserLogo = styled.img`
    height: 7.2vh;

    ${media.monitor} {
        height: 6vh;
    }

    ${media.large_desktop} {
        height: 5.5vh;
    }

    ${media.laptop} {
        height: 5.5vh;
    }

    ${media.tablet}{
        height: 6vh;
    }
`;
