import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { ErrorWrapper } from "../pages/Auth/style.Auth"

const Error = ({ isVisible, message }) => {
    return (
        <ErrorWrapper>
            {
                isVisible && (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <FaExclamationCircle style={{ padding: "0px 10px" }} />
                        <label>{message}</label>
                    </span>
                ) 
            }
        </ErrorWrapper>
    );
};

export default Error;