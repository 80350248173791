import React, { Component } from "react";
import { FiltersContainer } from "./style";
import { ResetFilter } from "./style";
import Filter from "./Filter";
import SingleSelectionFilter from "./SingleSelectionFilter";

import { defaultState, filterActions } from 'store/Home/filterReducer';

import { connect } from "react-redux";
import { isEqual } from "lodash";



class FiltersRow extends Component {

    filtersPresentation = new Map([
        ["sort", "Sort By"],
        ["state", "Location"],
        ["city", "City"],
        ["size", "Organization Size"],
        ["type", "Type"],
        ["focusAreas", "Focus Area"],
        // ["Technology", "Technology"],
        // ["Organisation_Type", "Organisation Type"],
        // ["Organisation_Size", "Organisation Size"],
        ["company_received_funding", "Private Funding Received"],
        ["status", "JLABS organizations"]
    ]);

    render() {
        let filterList = JSON.parse(JSON.stringify(this.props.filterValues));
        if (this.props.filterValues["state"] === "") {
            delete filterList.city;
        }
        const order = filterList.sort;
        delete filterList.sort;

        return (
            <div style={{ height: "3.5vh", zIndex: "10", marginTop: '3vh'}}>
                <FiltersContainer>

                    <SingleSelectionFilter
                        key={"sort"}
                        filterLabel={this.filtersPresentation.get("sort")}
                        filterName={"sort"}
                        filterValue={order}
                        options={this.props.filterOptions["sort"]}
                        clearTimeOutVaccine={this.props.clearTimeOutVaccine}
                        setTimeOutVaccine={this.props.setTimeOutVaccine}
                        ifSelected={this.props.ifSelected}
                    >
                        {"sort"}
                    </SingleSelectionFilter>

                    {Object.entries(filterList).map((entry) => {
                        const filterName = entry[0], value = entry[1];
                        return (
                            <Filter
                                key={filterName}
                                filterLabel={this.filtersPresentation.get(filterName)}
                                filterName={filterName}
                                filterValue={value}
                                options={this.props.filterOptions[filterName]}
                                clearTimeOutVaccine={this.props.clearTimeOutVaccine}
                                setTimeOutVaccine={this.props.setTimeOutVaccine}
                                ifSelected={this.props.ifSelected}
                            >
                                {filterName}
                            </Filter>
                        )
                    })}
                    { 
                        !isEqual(defaultState.filters, this.props.filterValues) &&
                            <ResetFilter onClick={this.props.ResetFilter}>Clear all</ResetFilter>
                    }

                </FiltersContainer>
            </div>
        )
    };
};

const mapStateToProps = state => {
    return {
        filterValues: state.homeFilter.filters,
        filterOptions: state.homeOptions.options,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ResetFilter: (obj) => dispatch(filterActions.clearFilter(obj)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FiltersRow);