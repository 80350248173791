import React, { useState, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "assets/image/wiser-logo.png";
import logoHeader from "assets/image/TitleImage2.png";
import downSelect from "assets/image/down-selected.png";
// import logoHeader2 from "assets/image/product_logo.png";
import { BiSearch } from "react-icons/bi";
import {IoChevronBack} from "react-icons/io5";
import { Input } from "components";
import { BackArrow, DownSelectArrow, HeaderTitle, HeaderTitleContainer, ScrollLogo, ScrollLogoContainer, SearchBar, SearchBarWapper, SubmitBtn, TitleContent, TitleImage2, UserName } from "./style.header";
import { HeaderComponent, LogoComponent, TitleImage } from "./style.header";
import { LinkTextButton } from "pages/Auth/style.Auth";

import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import withRouter from 'utils/ComponentWrapper';

{
  /* Header Section: Logo, Name, Sign in options */
}
const Header = (props) => {
    const histories = props.history;
    const { userLogOut } = useAuth();
    const navigate = useNavigate();



    // const [searchValue, setSearchValue] = useState(props.searchText);

    const [isScroll, setIsScroll] = useState(props.isHome);
    const [isAnimation, setIsAnimation] = useState(true);

    const AnimationRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio !== 1) {
                setIsScroll(true);
            }
        },
            {
                threshold: 1
            }
        );  
        
        observer.observe(AnimationRef.current);
    }, []);


    const handleSearchSubmit = () => {
        props.submitCallback(1);
    }

    const handleBackSubmit = () => {
        if (props.onBackClickCallback) {
            props.onBackClickCallback();
        } else {
            navigate(-1);
        }
    }


    return (
        <HeaderComponent
            ref={AnimationRef}
            isScrolled = {isScroll}
        >

            {/* Search Bar */}

            { /* Expanded Header */
                // !isScroll &&
                // <HeaderTitleContainer>
                //     <HeaderTitle>
                //         <LazyLoadImage id="org-logo" src={Logo} alt="Logo" />
                //         <TitleContent
                //             ref={AnimationRef}
                //         >
                //             Global Action Alliance
                //         </TitleContent>
                //     </HeaderTitle>
                // </HeaderTitleContainer>
            }

            {/* <SearchBarWapper
                isScroll={isScroll}
            > */}
            <BackArrow
                onClick={handleBackSubmit}>
                <span><IoChevronBack /></span>
                <span>back</span>
            </BackArrow>
            
            <LogoComponent onClick={() => { histories('/') }}>
                <LazyLoadImage id="org-logo" src={Logo} alt="Logo" />
                <TitleImage isScroll={isScroll} id="org-title" src={logoHeader} alt="JLabs WISE-R" />
                {/* {isScroll && <LazyLoadImage  id="org-logo" src={Logo} alt="Logo" />}
                {isScroll && <TitleImage isScroll={isScroll} id="org-title" src={logoHeader} alt="JLabs WISE-R" />} */}
                
                {/* {!isScroll && < TitleImage2 id="org-title" src={logoHeader} alt="JLabs WISE-R" />} */}
            </LogoComponent>

            <UserName
                data-tooltip={"Sign out"}
                onClick={() => { userLogOut() }}
                isDisabled={false}
            >
                <span>User Name</span>
                <DownSelectArrow src={downSelect} alt= "Down Select Arrow" />
            </UserName>

            {/* <LinkTextButton
                //TODO: Edit this link
                onClick={() => {
                    userLogOut();  
                    navigate("/login");
                }}
            >
                Log Out
            </LinkTextButton> */}

            {/* <UserName>
                <span>User Name</span>
                <DownSelectArrow src={downSelect} alt= "Down Select Arrow" />
            </UserName> */}

                {/* <SearchBar>
                    <Input
                        value={props.searchText}
                        onChangeCallback={props.handleSearchInput}
                        placeholder="Search by Organization name or Keyword"
                        keyDownCallback={handleSearchSubmit}
                    />
                    <SubmitBtn
                        onClick={handleSearchSubmit}
                        id="submit-btn"
                    >
                        <BiSearch />
                        <span>Search</span>
                    </SubmitBtn>
                </SearchBar>
            </SearchBarWapper> */}
        </HeaderComponent>
    );
}


export default withRouter(Header);
