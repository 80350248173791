import { React, Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LogInForm, SignupController,  } from "./pages/Auth"
import Home from "pages/Home/Home";
import HomeDetails from "pages/Home/HomeDetails/HomeDetails";
import { AuthProvider } from "contexts/AuthContext";
import PrivateRoute from "components/PrivateRoute";

class App extends Component {

    render() {
        return (
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route path="/login" element={<LogInForm />} />
                        <Route path="/signup" element={<SignupController />} />
                        
                        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                        <Route path="/:id" element = {<PrivateRoute><HomeDetails /></PrivateRoute>} />
                    </Routes>
                </AuthProvider>
            </Router>
        )
    }

}

export default App;