import React, { useState } from "react";
import { InfoInput } from "pages/Auth/style.Auth";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";

function Password(props){

    // const { value, onChangeCallback, keyDownCallback } = props;
    const [ passwordVisibility, setPasswordVisibility ] = useState(false);

    const togglePassword = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <InfoInput
                type={ passwordVisibility ? "text" : "password" }
                value={props.value}
                ref={props.innerRef}
                required={props.required}
                style={{ padding: "0px 4vw 0px 2vw" }}
                // className={props.className?.includes("invalid") ? "invalid" : ""}
                onChangeCallback={(e) => {props.onChangeCallback(e)}}
                onFocusCallback={(e) => {props.onFocusCallback(e)}}
                onBlurCallback={(e) => {props.onBlurCallback(e)}}
                placeholder={props.placeholder}
                keyDownCallback={props.keyDownCallback}
            >
                
            </InfoInput>
            <span style={{ margin: "0 0 0 -3vw", cursor:'pointer' }} onClick={togglePassword}>
                {
                    passwordVisibility 
                    ? <FaEye style={{ width: "1.3vw", height: "2vh"}} /> 
                    : <FaRegEyeSlash style={{ width: "1.3vw", height: "2vh"}} />
                }
            </span>
        </div>
    );
}

export default Password;