import { useState, useEffect } from 'react';
import { TabsRow, TabsLabel } from './style.tabs';
import { processNumberString } from "utils/Function/number";

const Tabs = (props) => {
    const [stats, setStats] = useState(() => ({
        Prequalified: "20",
        Vaccine_Availability: "12"
    }));

    return (
        <TabsRow>
            {
                props.linkStats && props.linkStats.length > 0 ?
                    props.linkStats.map((item, index) => {
                        const link = Object.entries(item);
                        const [ label, value ] = link[0];

                        if (value) 
                            return (
                                <TabsLabel key={index}>
                                    <p>
                                        <a href={`https://${value}`} target="_blank">
                                            {label}
                                        </a>
                                    </p>
                                </TabsLabel>
                            );
                    })    
                    :<div></div>
            }
            {props.stats && Object.entries(props.stats).map((value, index) => {
                return (
                    <TabsLabel key={index}>
                        <p>
                            <b>{ processNumberString(value[1] ?? 0) }</b>
                            &nbsp;&nbsp;
                            { value[0].split('_').join(" ") }
                        </p>
                    </TabsLabel>
                )
            })
            }

        </TabsRow>
    );
}

export default Tabs;