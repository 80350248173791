import React, { useState, useEffect } from 'react';
import { Input } from "./style";


export default function FilterInput({ filterLabel, filterValue }) {

    const [inputWidth, setInputWidth] = useState('auto');

    const value = filterValue || filterLabel;

    useEffect(() => {
        if (value) {
            const placeholderWidth = value.length * 8;
            setInputWidth(placeholderWidth + 'px');
        }
    }, [value]);

    return (
        <Input
            name={filterLabel}
            placeholder={filterLabel}
            type="text"
            value={value}
            autocomplete={false}
            readOnly={true}
            style={{ width: inputWidth }}
        />
    )
}