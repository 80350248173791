import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css'
import {Tooltip} from "react-tooltip";

// import ReactUSA from 'react-usa';

import { ComposableMap, Geographies, Geography } from "react-simple-maps";
// import { scaleQuantize } from "d3-scale";
// import { csv } from "d3-fetch";
// const colorScale = scaleQuantize()
//   .domain([1, 10])
//   .range([
//     "#ffedea",
//     "#ffcec5",
//     "#ffad9f",
//     "#ff8a75",
//     "#ff5533",
//     "#e2492d",
//     "#be3d26",
//     "#9a311f",
//     "#782618"
//   ]);

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";



export default function Map({data}) {
    // const mapboxAccessToken = "pk.eyJ1IjoiZm9rdmF0IiwiYSI6ImNsZjFhZnM5MTA2bjczcXA4eDlwODZ0bGoifQ.AQvVjrRjBJyOiE_-0DQq_w" // Your access token
    // const mapboxType = "streets";
    // const position = [35.7596, -79.0193];
    // const zoom = 5;
    // const stateStyle = { weight: 1, opacity: 1, color: '#DED3E6', dashArray: '0', fillOpacity: 0.3 };
    // const stateHoverStyle = { weight: 1, color: '#FFF', dashArray: '1', fillOpacity: 0.7 };
    // const excludeStates = ["District of Columbia", "Puerto Rico"];
    // console.log(data);
    const [content, setContent] = useState("USA");

    return (
        <>
            <ComposableMap projection="geoAlbersUsa">
                <Geographies geography={geoUrl}>
                    {({ geographies }) => 
                        geographies.map(geo => {
                            // console.log(geo);
                            let cur;
                            if (data) {
                                cur = data.find(s => s.name === geo.properties.name);
                            }
                            // console.log(cur);
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    // fill={"#F0F0EF"}
                                    // stroke={"#DED3E6"}
                                    style={{
                                        default: {
                                            fill: `${cur ? cur.color :'#F0F0EF'}`,
                                            stroke: "#DED3E6"
                                        },
                                        hover: {
                                            fill: `${cur ? cur.color :'#DED3E6'}`,
                                            stroke: "#DED3E6"
                                        },
                                        // pressed: {
                                        //     fill: "#E42",
                                        //     outline: "none"
                                        // }
                                    }}
                                    className="my-anchor-element"
                                    data-tooltip-content={`${geo.properties.name}${cur ? `: ${cur.values[0].val}` : ''}`}
                                    data-tooltip-float={true}
                                    data-tooltip-variant="info"
                                    data-tooltip-offset={20}
                                //  fill={colorScale(cur ? cur.unemployment_rate : "#EEE")}
                                />
                            );
                        })
                }
                </Geographies>
            </ComposableMap>
            <Tooltip
                anchorSelect=".my-anchor-element"
                place="bottom"
                style={{backgroundColor:"#FFFFFF", color:'#000000',boxShadow:"3px 12px 20px rgba(0, 0, 0, 0.25);", borderRadius:'4px', boxSizing:'border-box', maxWidth:"10vw", opacity:"1"}}
            />
        </>
        // <ReactUSA
        //     mapboxAccessToken={mapboxAccessToken} // Required
        //     mapHeight="280px" // Required
        //     mapWidth="100%"
        //     className="container"
        //     mapboxType={mapboxType}
        //     mapCenter={position}
        //     mapZoom={zoom}
        //     mapScrollZoom={true}
        //     neighborhoodOn={true}
        //     tooltip={true}
        //     tooltipSticky={false}
        //     data={data}
        //     stateStyle={stateStyle}
        //     stateHoverStyle={stateHoverStyle}
        //     // excludeStates={excludeStates}
        // />
    )
}
